import { VueConstructor } from 'vue/types/vue';

const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';

type ErrorShape = string | Record<string, any>;

/**
 * Get an error code from an error (for UI error checks)
 *
 * @param   error - Error object/string
 * @returns Error code
 */
const getErrorCode = (error: ErrorShape): string | null => {
  if (!error) return null;
  if (typeof error !== 'object') return null;

  if (error.response && error.response.data) {
    return error.response.data.code;
  }

  return null;
};

/**
 * Get an error message from an error
 *
 * @param   {Object|string} error - Error object/string
 * @returns {string}        Error message
 */
const getErrorMessage = (error: ErrorShape): string | null => {
  if (!error) return null;

  // Errors are often provided as an object, but the message 'key' may vary
  if (typeof error === 'object') {
    let message = error.message || error.messages;
    if (error.response && error.response.data) {
      message = error.response.data.message;
      return Array.isArray(message) ? message[0] : message;
    }
    if (message) return message;
  }
  // A bare error code may be provided instead of an error object
  else if (typeof error === 'string') {
    return error;
  }

  return null;
};

/**
 * Get an error message from an error
 *
 * @param   {Object|string} error          - Error object/string
 * @param   {string}        defaultMessage - Default error message
 * @returns {string}        Error message
 */
const getError = (error: ErrorShape, defaultMessage?: string): string => {
  if (!error) return '';

  const errorMessage = getErrorMessage(error);
  if (!errorMessage) return defaultMessage ?? DEFAULT_ERROR_MESSAGE;

  return errorMessage;
};

/**
 * Determine whether an error includes a specific error code
 *
 * @param   {Object|string} error           - Error object/string
 * @param   {string}        targetErrorCode - Target error code
 * @returns {boolean}       Whether error includes specific error code
 */
const hasError = (error: ErrorShape, targetErrorCode: string): boolean => {
  if (!error) return false;

  const errorCode = getErrorCode(error);
  if (!errorCode) return false;

  return errorCode === targetErrorCode;
};

const ErrorsPlugin = {
  install: (Vue: VueConstructor): void => {
    Vue.prototype.$getError = getError;
    Vue.prototype.$getErrorCode = getErrorCode;
    Vue.prototype.$hasError = hasError;
  }
};

export default ErrorsPlugin;
export { getError, getErrorCode, hasError };
