import {
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';

// Utilities
import config from '@/config';
import store from '@/store';

// Types
// import { IAuthUser } from '@/types/user.types';

const moduleName = 'members';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!config.production && store.state[moduleName]) {
  store.unregisterModule(moduleName);
}

@Module({ dynamic: true, name: moduleName, namespaced: true, store })
class MembersState extends VuexModule {
  causeInformation: any[] = [];
  donutChartData: any[] = [];

  /** Get Cause Information */
  get getCauseInformation(): any | null {
    return this.causeInformation;
  }

  /** Get donut chart data */
  get getDonutChartData(): any | null {
    return this.donutChartData;
  }

  /**
   * Initialize state
   */
  @Mutation
  initialize(): void {
    this.causeInformation = [];
    this.donutChartData = [];
  }

  /**
   * Set the Cause Information
   *
   * @param causeInfo - Cause (Charity) Information
   */
  @Mutation
  setCauseInformation(causeInfo: any): void {
    this.causeInformation = causeInfo;
  }

  /**
   * Set donut chart data
   *
   * @param donutData - donut chart data
   */
  @Mutation
  setDonutChartData(donutData: any): void {
    this.donutChartData = donutData;
  }

  /**
   * Clear the Cause Information
   */
  @Mutation
  removeOffers(): void {
    this.causeInformation = [];
  }
}

export { MembersState };
export default getModule(MembersState);
