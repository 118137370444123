import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// Utilities
import store from '@/store';

// Types
import { IFeaturesStatuses } from '@/types/feature.types';

@Module({ dynamic: true, name: 'feature', namespaced: true, store })
class FeatureState extends VuexModule {
  isEcosystemFeeEnabled = false;
  isOffersEnabled = false;
  isRoundUpEnabled = false;
  isMultipleCauseEnabled = false;

  /** Retrieve current feature statuses */
  get getFeatureStatuses(): IFeaturesStatuses {
    return {
      isEcosystemFeeEnabled: this.isEcosystemFeeEnabled,
      isOffersEnabled: this.isOffersEnabled,
      isRoundUpEnabled: this.isRoundUpEnabled,
      isMultipleCauseEnabled: this.isMultipleCauseEnabled
    };
  }

  /** Retrieve whether ecosystem fee feature is enabled */
  get getIsEcosystemFeeEnabled(): boolean {
    return this.isEcosystemFeeEnabled;
  }

  /** Retrieve whether offers feature is enabled */
  get getIsOffersEnabled(): boolean {
    return this.isOffersEnabled;
  }

  /** Retrieve whether round up feature is enabled */
  get getIsRoundUpEnabled(): boolean {
    return this.isRoundUpEnabled;
  }

  /** Retrieve whether multiple cause feature is enabled */
  get getIsMultipleCauseEnabled(): boolean {
    return this.isMultipleCauseEnabled;
  }

  @Action
  initialize(): void {
    this.setFeatureStatuses({
      isEcosystemFeeEnabled: false,
      isOffersEnabled: false,
      isRoundUpEnabled: false,
      isMultipleCauseEnabled: false
    });
  }

  /**
   * Set featureStatuses
   *
   * @param featuresStatuses    - Feature statuses
   */
  @Mutation
  setFeatureStatuses(featuresStatuses: IFeaturesStatuses): void {
    this.isEcosystemFeeEnabled = featuresStatuses.isEcosystemFeeEnabled;
    this.isOffersEnabled = featuresStatuses.isOffersEnabled;
    this.isRoundUpEnabled = featuresStatuses.isRoundUpEnabled;
    this.isMultipleCauseEnabled = featuresStatuses.isMultipleCauseEnabled;
  }
}

export default getModule(FeatureState);
