export const tenants: { [key: string]: any } = {
  ToyDrive: {
    id: 'ToyDrive',
    name: 'Leaside Toy Drive',
    email: 'hello@leasidelocal.com',
    headerName: 'Toy Drive Local',
    headerBackgroundImage: '/images/toy-drive/header-bg.png',
    headerBackgroundOverlay: '/images/toy-drive/header-bg-overlay.png',
    iconAddress: '/images/toy-drive/icon-address.svg',
    iconPhone: '/images/toy-drive/icon-phone.svg',
    iconWeb: '/images/toy-drive/icon-web.svg',
    partner: 'Leaside Life',
    text: {
      boosterCardAccent: '#273b56 !important',
      offerCardAccent: '#81171A !important',
      offersForYouDescription:
        'Support your community by shopping at these participating local Merchants and helping the Leaside Toy Drive.',
      offerCardHeaderPrimaryBackground: '#283c55',
      offerCardHeaderSecondaryBackground: '#81171A',
      onboardImage: '/images/toy-drive/onboard_full.png',
      onboardLayoutTitleText: 'Enrolling in the Toy Drive Local program is easy and secure.',
      onboardLayoutTitleColor: '#131315',
      onboardFirstStepTitleText: 'Create a Toy Drive Local Account',
      onboardFirstStepDescriptionText: 'Create an account to explore the Toy Drive Local program.',
      onboardLayoutBackground: '#e5bc00',
      onboardLayoutButtonBackground: '#4391ca !important',
      onboardLayoutButtonBorder: '#455724',
      onboardLayoutMobileSetupTitle: 'Why do we need your Mobile Number?',
      onboardLayoutMobileSetupTextPrimary:
        'We can send you messages as soon as you make a purchase, so you instantly know the difference you’re making in your community.',
      onboardLayoutMobileSetupTextSecondary:
        '(But if you really don’t want to include your phone number, no problem. Your purchase will still count.)',
      onboardLayoutMarketingText:
        'You agree to receive occasional notifications of new offers, or updates to the program or campaign, via the following channels. You can always update these preferences later in your settings. If you consent to us contacting you for this purpose, please select one or both of the communication methods below.',
      onboardLayoutMarketingTextSecondary:
        'LeasideLocal and Hope Factory are committed to protecting and respecting your privacy. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
      exploreOfferTitle: 'Explore Offers',
      exploreOfferDescription:
        'Check out some of our Toy Drive Local offers that you might not have seen yet!',
      impactDashboardDescription:
        'By participating in Toy Drive Local offers, you are supporting local Merchants in your community, and helping support the Leaside Toy Drive!',
      mySocialImpactDescription:
        'Here’s how much you’ve helped the Leaside Toy Drive by participating in Toy Drive Local offers.',
      programSpendDescription:
        'Here’s how much you spent on Toy Drive Offers, in support of local Merchants, through the program!'
    },
    links: {
      partner: 'https://leasidelife.com/',
      facebook: 'https://facebook.com/LeasideToyDrive/',
      twitter: 'https://twitter.com/leasidetoy',
      instagram: 'https://instagram.com/theleasidetoydrive/',
      privacyPolicy: 'https://www.leasidetoydrive.ca/toydrivelocal/privacy/',
      termsConditions: 'https://www.leasidetoydrive.ca/toydrivelocal/terms/'
    },
    headerLogo: '/images/toy-drive/leaside-logo.png',
    headerLogoSize: {
      height: '24',
      width: '149'
    },
    headerButtonColor: '#f5f5f5',
    menuHeaderBackground: 'white',
    menuHeaderTextColor: '#000000',
    yellowArrow: '/images/toy-drive/yellow-arrow.svg',
    background: {
      boosterCardAccent: '#273b56'
    },
    roundUpBoosterCause: 'The Leaside Toy Drive',
    colorScheme: {
      dark: false,
      main: '#e5bc00'
    },
    onboarding: {
      currentStepColor: '#81171a',
      pendingStepColor: '#131315'
    },
    appHeader: {
      upperHeaderImage: '/images/leaside-life-presents.svg',
      upperHeaderColor: '#273b56',
      lowerHeaderImage: '/images/leaside-local.svg',
      lowerHeaderHeight: 25,
      mobileHeaderImage: '/images/presented-by-ll.svg',
      mobileHeaderSize: 50,
      barColor: '#3D4D24'
    },
    offerCard: {
      buttonColor: '#273b56'
    },
    roundUps: {
      arrowUpIconColor: '#e5bc00',
      arrowUpIconColorSecondary: '#000',
      bannerColor: '#005a8c !important',
      progressBarColor: '#005a8c',
      textColor: '#005a8c',
      titleColor: '#005a8c',
      bannerText:
        'By opting into the Round UP program you be able to make an even bigger difference to the Leaside Toy Drive.',
      bannerTextSecondary: 'Every little bit helps!',
      bannerTextColor: '#FFF',
      enrollRoundUpTitle: 'Enroll in the Toy Drive Local Round UP Program',
      enrollRoundUpTitleColor: 'rgba(0, 0, 0, 0.87)',
      enrollRoundUpDescriptionText:
        'Our Round UP program lets you make a bigger impact by rounding up all purchases you make with your enrolled credit card and donating those funds to our Cause. This way, each time you spend for yourself, you are helping others too.',
      enrollRoundUpDescriptionTextColor: '#737373',
      enrollRoundUpPlatformFeeText:
        'Would you like to help cover the platform fees so that more of your Round UPs go to this great cause?',
      dashboardImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/happy-kids+(1).png'
    },
    referralProgram: {
      referralTextPrimary:
        'Do you love LeasideLocal and doing good in your community as much as we do? Tell everyone you know! Simply copy your referral code below, and send it to all your friends and family or even share it on your social channels!',
      referralTextSecondary:
        'When you share your referral code with friends and family to join LeasideLocal you could be entered to win amazing gifts from our participating local Merchants and Booster Businesses!'
    },
    causeSetting: {
      title: 'Manage Your Charity',
      description:
        'If you would like to change the Charity you are currently rounding for, please select a new one below.'
    },
    userAvatar: {
      backgroundColor: '#e5bc00',
      textColor: '#273b56',
      mobileBackgroundColor: '#273b56',
      mobileTextColor: '#FFFFFF'
    },
    searchLocations: {
      title: 'You Spend Local. They Donate.'
    },
    tab: {
      title: 'LeasideLocal',
      icon: 'favicon-leaside'
    },
    pieChart: {
      primary: '#0081A4',
      secondary: '#FAE800',
      teritary: '#51949E'
    }
  },
  FoodInitiative: {
    id: 'FoodInitiative',
    name: 'Thorncliffe Food Bank',
    email: 'hello@leasidelocal.com',
    headerName: 'LeasideLocal',
    headerBackgroundImage: '/images/food-initiative/app_bkgrd.jpg',
    headerBackgroundOverlay: '/images/food-initiative/app_overlay.png',
    iconAddress: '/images/food-initiative/icon-address.svg',
    iconPhone: '/images/food-initiative/icon-phone.svg',
    iconWeb: '/images/food-initiative/icon-web.svg',
    partner: 'Leaside Life',
    text: {
      boosterCardAccent: '#273b56 !important',
      offerCardAccent: '#81171A !important',
      offersForYouDescription:
        'Our participating Merchants and Boosters understand the value of community and the needs of society. The businesses below are pleased to support the LeasideLocal platform which converts your local spend into fundraising for important causes and charities like Leaside Toy Drive.',
      offersForYouDescriptionColor: '#131315',
      offersForYouDescriptionTitleColor: '#131315',
      exploreOfferTitle: 'Explore Offers',
      exploreOfferDescription: 'Check out some of our LeasideLocal offers that you might not have seen yet!',
      impactDashboardDescription:
        'By participating in LeasideLocal offers, you are supporting local Merchants in your community, and helping support important causes and charities.',
      mySocialImpactDescription: 'Here’s how much you’ve helped by participating in LeasideLocal offers.',
      programSpendDescription:
        'Here’s how much you spent on LeasideLocal offers, in support of local Merchants, through the program!'
    },
    links: {
      partner: 'https://leasidelife.com/',
      facebook: 'https://www.facebook.com/leasidelocal/',
      twitter: 'https://twitter.com/leasidelife',
      instagram: 'https://www.instagram.com/leasidelocal/',
      privacyPolicy: 'https://leasidelocal.com/privacy-policy',
      termsConditions: 'https://leasidelocal.com/terms-conditions',
      hopeFactory: 'https://www.hopefactory.net/',
      faqs: 'https://leasidelocal.com/faqs/'
    },
    headerLogo: '/images/food-initiative/logo-header-new.png',
    headerLogoSize: {
      height: '90',
      width: '215'
    },
    headerButtonColor: '#f5f5f5',
    menuHeaderBackground: '#e5bc00',
    menuHeaderTextColor: '#000000',
    offerCardHeaderPrimaryBackground: '#283c55',
    offerCardHeaderSecondaryBackground: '#81171A',
    onboardImage: '/images/food-initiative/onboard_full.png',
    onboardLayoutTitleText: 'Enrolling in the LeasideLocal program is easy and secure.',
    onboardLayoutTitleColor: '#131315',
    onboardFirstStepTitleText: 'Create a LeasideLocal Account',
    onboardFirstStepDescriptionText: 'Create an account to explore the LeasideLocal program.',
    onboardLayoutBackground: '#e5bc00',
    onboardLayoutButtonBackground: '#4391ca !important',
    onboardLayoutButtonBorder: '#455724',
    onboardLayoutMobileSetupTitle: 'Why do we need your Mobile Number?',
    onboardLayoutMobileSetupTextPrimary:
      'We can send you messages as soon as you make a purchase, so you instantly know the difference you’re making in your community.',
    onboardLayoutMobileSetupTextSecondary:
      '(But if you really don’t want to include your phone number, no problem. Your purchase will still count.)',
    onboardLayoutMarketingText:
      'You agree to receive occasional notifications of new offers, or updates to the program or campaign, via the following channels. You can always update these preferences later in your settings. If you consent to us contacting you for this purpose, please select one or both of the communication methods below.',
    onboardLayoutMarketingTextSecondary:
      'LeasideLocal and Hope Factory are committed to protecting and respecting your privacy. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
    yellowArrow: '/images/food-initiative/yellow-arrow.svg',
    footerBackground: '#3d4d24',
    background: {
      boosterCardAccent: '#273b56'
    },
    roundUpBoosterCause: 'Leaside Local',
    colorScheme: {
      dark: false,
      main: '#e5bc00'
    },
    onboarding: {
      currentStepColor: '#81171a',
      pendingStepColor: '#131315'
    },
    appHeader: {
      upperHeaderImage: '/images/leaside-life-presents.svg',
      upperHeaderColor: '#273b56',
      lowerHeaderImage: '/images/leaside-local.svg',
      lowerHeaderHeight: 25,
      mobileHeaderImage: '/images/presented-by-ll.svg',
      mobileHeaderSize: 50,
      barColor: '#3D4D24'
    },
    offerCard: {
      buttonColor: '#273b56'
    },
    roundUps: {
      arrowUpIconColor: '#e5bc00',
      arrowUpIconColorSecondary: '#000',
      bannerColor: '#005a8c !important',
      progressBarColor: '#005a8c',
      textColor: '#005a8c',
      titleColor: '#005a8c',
      bannerText:
        'By opting into the Round UP program you be able to make an even bigger difference to the Leaside Toy Drive.',
      bannerTextSecondary: 'Every little bit helps!',
      bannerTextColor: '#FFF',
      enrollRoundUpTitle: 'Enroll in the LeasideLocal Round UP Program',
      enrollRoundUpTitleColor: 'rgba(0, 0, 0, 0.87)',
      enrollRoundUpDescriptionText:
        'Our Round UP program lets you make a bigger impact by rounding up all purchases you make with your enrolled credit card and donating those funds to our Cause. This way, each time you spend for yourself, you are helping others too.',
      enrollRoundUpDescriptionTextColor: '#737373',
      enrollRoundUpPlatformFeeText:
        'Would you like to help cover the platform fees so that more of your Round UPs go to this great cause?',
      dashboardImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/happy-kids+(1).png'
    },
    referralProgram: {
      referralTextPrimary:
        'Do you love LeasideLocal and doing good in your community as much as we do? Tell everyone you know! Simply copy your referral code below, and send it to all your friends and family or even share it on your social channels!',
      referralTextSecondary:
        'When you share your referral code with friends and family to join LeasideLocal you could be entered to win amazing gifts from our participating local Merchants and Booster Businesses!'
    },
    causeSetting: {
      title: 'Manage Your Charity',
      description:
        'If you would like to change the Charity you are currently rounding for, please select a new one below.'
    },
    userAvatar: {
      backgroundColor: '#e5bc00',
      textColor: '#273b56',
      mobileBackgroundColor: '#273b56',
      mobileTextColor: '#FFFFFF'
    },
    searchLocations: {
      title: 'You Spend Local. They Donate.'
    },
    tab: {
      title: 'LeasideLocal',
      icon: 'favicon-leaside'
    },
    pieChart: {
      primary: '#0081A4',
      secondary: '#FAE800',
      teritary: '#51949E'
    }
  },
  ToyDrive2022: {
    id: 'ToyDrive2022',
    name: 'Hope Factory Community',
    email: 'hello@hopefactory.ca',
    headerName: 'Hope Factory Community',
    headerBackgroundImage: '/images/toy-drive-2022/app_bkgrd.png',
    headerBackgroundOverlay: '/images/toy-drive-2022/app_overlay.png',
    iconAddress: '/images/toy-drive-2022/icon-address.svg',
    iconPhone: '/images/toy-drive-2022/icon-phone.svg',
    iconWeb: '/images/toy-drive-2022/icon-web.svg',
    partner: 'Leaside Life',
    text: {
      boosterCardAccent: '#81171A !important',
      offerCardAccent: '#81171A !important',
      offersForYouDescription:
        'Our participating Merchants and Boosters understand the value of community and the needs of society. The businesses below are pleased to support the Hope Factory Community which converts your local spend into fundraising for important causes and charities.',
      offersForYouDescriptionColor: '#000000',
      offersForYouDescriptionTitleColor: '#7CB81B',
      impactDashboardTitleColor: '#7CB81B',
      exploreOfferTitle: 'Explore Offers',
      exploreOfferDescription: 'Check out some of our LeasideLocal offers that you might not have seen yet!',
      impactDashboardDescription:
        'By participating in the Hope Factory Community, you are supporting local Merchants in your community, and helping support important causes and charities.',
      mySocialImpactDescription:
        'Here’s how much you’ve helped by participating in the Hope Factory Community.',
      programSpendDescription:
        'Here’s how much you spent on LeasideLocal offers, in support of local Merchants, through the program!'
    },
    links: {
      partner: 'https://leasidelife.com/',
      facebook: 'https://www.facebook.com/wearethehopefactory',
      twitter: 'https://twitter.com/leasidelife',
      instagram: 'https://www.instagram.com/wearethehopefactory/',
      privacyPolicy: 'https://hopefactory.net/privacy-policy',
      termsConditions: 'https://hopefactory.net/terms-and-conditions/',
      hopeFactory: 'https://hopefactory.net/',
      linkedin: 'https://www.linkedin.com/company/hopefactory/',
      faqs: 'https://hopefactory.net/faqs/'
    },
    headerLogo: '/images/hopefactory-sales/hf-logo.svg',
    headerLogoSize: {
      height: '90',
      width: '215'
    },
    headerButtonColor: '#FFFFFF',
    menuHeaderBackground: '#000000',
    menuHeaderTextColor: '#FFFFFF',
    offerCardHeaderPrimaryBackground: '#283c55',
    offerCardHeaderSecondaryBackground: '#81171A',
    onboardImage: '/images/toy-drive-2022/onboard_full.png',
    onboardLayoutTitleText: 'Enrolling in the Hope Factory Community is easy and secure.',
    onboardLayoutTitleColor: '#FFFFFF',
    onboardFirstStepTitleText: 'Create a LeasideLocal Account',
    onboardFirstStepDescriptionText: 'Create an account to explore the LeasideLocal program.',
    onboardLayoutBackground: '#000000',
    onboardLayoutButtonBackground: '#7CB81B !important',
    onboardLayoutButtonBorder: '#455724',
    onboardLayoutMobileSetupTitle: 'Why do we need your Mobile Number?',
    onboardLayoutMobileSetupTextPrimary:
      'We can send you messages as soon as you make a purchase, so you instantly know the difference you’re making in your community.',
    onboardLayoutMobileSetupTextSecondary:
      '(But if you really don’t want to include your phone number, no problem. Your purchase will still count.)',
    onboardLayoutMarketingText:
      'You agree to receive occasional notifications of new offers, or updates to the program or campaign, via the following channels. You can always update these preferences later in your settings. If you consent to us contacting you for this purpose, please select one or both of the communication methods below.',
    onboardLayoutMarketingTextSecondary:
      'Hope Factory is committed to protecting and respecting your privacy. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
    yellowArrow: '/images/toy-drive-2022/yellow-arrow.svg',
    footerBackground: '#000000',
    background: {
      boosterCardAccent: '#3d4d24'
    },
    blueTextColor: '#000000',
    roundUpBoosterCause: 'The Leaside Toy Drive',
    colorScheme: {
      dark: false,
      main: '#e5bc00'
    },
    onboarding: {
      currentStepColor: '#7CB81B',
      pendingStepColor: '#FFFFFF'
    },
    appHeader: {
      upperHeaderImage: '/images/hopefactory-sales/hf-logo.svg',
      upperHeaderColor: '#000000',
      lowerHeaderImage: '/images/leaside-local.svg',
      lowerHeaderHeight: 25,
      mobileHeaderImage: '/images/presented-by-ll.svg',
      mobileHeaderSize: 50,
      barColor: '#3D4D24'
    },
    offerCard: {
      buttonColor: '#273b56'
    },
    roundUps: {
      arrowUpIconColor: '#7CB81B',
      arrowUpIconColorSecondary: '#FFFFFF',
      bannerColor: '#51AB9E !important',
      progressBarColor: '#7CB81B',
      textColor: '#FFFFFF',
      titleColor: '#000000',
      bannerText:
        'By opting into the Round UP program you be able to make an even bigger difference to the Leaside Toy Drive.',
      bannerTextSecondary: 'Every little bit helps!',
      bannerTextColor: '#000000',
      enrollRoundUpTitle: 'Enroll in the Hope Factory Round UP Program',
      enrollRoundUpTitleColor: 'rgba(0, 0, 0, 0.87)',
      enrollRoundUpDescriptionText:
        'Our Round UP program lets you make a bigger impact by rounding up all purchases you make with your enrolled credit card and donating those funds to our Cause. This way, each time you spend for yourself, you are helping others too.',
      enrollRoundUpDescriptionTextColor: '#737373',
      enrollRoundUpPlatformFeeText:
        'Would you like to help cover the platform fees so that more of your Round UPs go to this great cause?',
      dashboardImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/happy-kids+(1).png'
    },
    referralProgram: {
      referralTextPrimary:
        'Do you love LeasideLocal and doing good in your community as much as we do? Tell everyone you know! Simply copy your referral code below, and send it to all your friends and family or even share it on your social channels!',
      referralTextSecondary:
        'When you share your referral code with friends and family to join LeasideLocal you could be entered to win amazing gifts from our participating local Merchants and Booster Businesses!'
    },
    causeSetting: {
      title: 'Manage Your Charity',
      description:
        'If you would like to change the Charity you are currently rounding for, please select a new one below.'
    },
    userAvatar: {
      backgroundColor: '#7CB81B',
      textColor: '#FFFFFF',
      mobileBackgroundColor: '#FFFFFF',
      mobileTextColor: '#000000'
    },
    searchLocations: {
      title: 'You Spend. They Donate!'
    },
    tab: {
      title: 'Hope Factory Community',
      icon: 'favicon-leaside'
    },
    pieChart: {
      primary: '#7CB81B',
      secondary: '#51AB9E',
      teritary: '#707070'
    }
  },
  HopefactorySales: {
    id: 'HopefactorySales',
    name: 'Thorncliffe Food Bank',
    email: 'hello@hopefactory.ca',
    headerName: 'Hope Factory',
    headerBackgroundImage: '/images/hopefactory-sales/main-image-secondharvest.png',
    headerBackgroundOverlay: null,
    iconAddress: '/images/hopefactory-sales/icon-address.svg',
    iconPhone: '/images/hopefactory-sales/icon-phone.svg',
    iconWeb: '/images/hopefactory-sales/icon-web.svg',
    partner: 'Leaside Life',
    text: {
      boosterCardAccent: '#0F0D0D !important',
      offerCardAccent: '#0F0D0D !important',
      offersForYouDescription:
        'These great businesses are listed only for the purpose of demonstrating Hope Factory capabilities. No money will be donated by these businesses or other organizations. For Demo Purposes Only!',
      offersForYouDescriptionColor: '#131315',
      offersForYouDescriptionTitleColor: '#51AB9E',
      impactDashboardTitleColor: '#0F0D0D',
      exploreOfferTitle: 'Explore Offers',
      exploreOfferDescription: 'Check out some of our LeasideLocal offers that you might not have seen yet!', //x
      impactDashboardDescription:
        'By participating in Hope Factory, you are helping support important causes and charities, as well as local Merchants in your community!',
      mySocialImpactDescription:
        'Here’s how much you’ve helped by participating in the Hope Factory program.',
      programSpendDescription:
        'Here’s how much you spent on Hope Factory offers, in support of local Merchants, through the program!'
    },
    links: {
      partner: null,
      facebook: 'https://www.facebook.com/wearethehopefactory',
      twitter: 'https://twitter.com/leasidelife',
      instagram: 'https://www.instagram.com/wearethehopefactory/',
      privacyPolicy: 'https://hopefactory.net/privacy-policy',
      termsConditions: 'https://hopefactory.net/terms-and-conditions/',
      hopeFactory: 'https://www.hopefactory.net/',
      faqs: 'https://hopefactory.net/faqs/'
    },
    headerLogo: '/images/hopefactory-sales/hf-logo.svg',
    headerLogoSize: {
      height: '90',
      width: '215'
    },
    headerButtonColor: '#f5f5f5',
    menuHeaderBackground: '#0F0D0D',
    menuHeaderTextColor: '#ffffff',
    offerCardHeaderPrimaryBackground: '#51AB9E',
    offerCardHeaderSecondaryBackground: '#0F0D0D',
    onboardImage: '/images/hopefactory-sales/onboard-secondharvest.png',
    onboardLayoutTitleText: 'Enrolling in the Hope Factory program is easy and secure.',
    onboardLayoutTitleColor: '#131315',
    onboardFirstStepTitleText: 'Create a Hope Factory Account',
    onboardFirstStepDescriptionText: 'Create an account to explore the Hope Factory program.',
    onboardLayoutBackground: '#7CB81B',
    onboardLayoutButtonBackground: '#0F0D0D !important',
    onboardLayoutButtonBorder: null,
    onboardLayoutMobileSetupTitle: 'Why give us your mobile number?',
    onboardLayoutMobileSetupTextPrimary:
      'We can send you transactional SMS messages about your Hope Factory program activity including updates on your monthly Round UPs and Offer redemptions, so you instantly know the difference you’re making in your community.',
    onboardLayoutMobileSetupTextSecondary: 'This is optional and you can opt out at any time.',
    onboardLayoutMarketingText:
      'You agree to receive occasional notifications of new offers, or updates to the program or campaign, via the following channels. You can always update these preferences later in your settings. If you consent to us contacting you for this purpose, please select one or both of the communication methods below.',
    onboardLayoutMarketingTextSecondary:
      'Hope Factory is committed to protecting and respecting your privacy. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
    yellowArrow: '/images/hopefactory-sales/yellow-arrow.svg',
    footerBackground: '#0F0D0D',
    background: {
      boosterCardAccent: '#0F0D0D'
    },
    blueTextColor: '#1181C8',
    roundUpBoosterCause: 'The Leaside Toy Drive',
    colorScheme: {
      dark: true,
      main: '#0F0D0D'
    },
    onboarding: {
      currentStepColor: '#0F0D0D',
      pendingStepColor: '#FFFFFF'
    },
    appHeader: {
      upperHeaderImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/cdn-demo.png',
      upperHeaderColor: '#7CB81B',
      lowerHeaderImage: '/images/hopefactory-sales/hf-logo.svg',
      lowerHeaderHeight: 25,
      mobileHeaderImage: '/images/presented-by-hf-sales.png',
      mobileHeaderSize: 70,
      barColor: '#000'
    },
    offerCard: {
      buttonColor: '#51AB9E'
    },
    roundUps: {
      arrowUpIconColor: '#7CB81B',
      arrowUpIconColorSecondary: '#FFFFFF',
      bannerColor: '#51AB9E !important',
      progressBarColor: '#7CB81B',
      textColor: '#0F0D0D',
      titleColor: '#51AB9E',
      bannerText:
        'By opting into the Round UP program you will be able to make an even bigger difference in your community.',
      bannerTextSecondary: 'Every little bit helps!',
      bannerTextColor: '#FFF',
      enrollRoundUpTitle: 'Enroll in the Hope Factory Round UP Program',
      enrollRoundUpTitleColor: 'rgba(0, 0, 0, 0.87)',
      enrollRoundUpDescriptionText:
        'Our Round UP program lets you make a bigger impact by rounding up all purchases you make with your enrolled credit card and donating those funds to our Cause. This way, each time you spend for yourself, you are helping others too.',
      enrollRoundUpDescriptionTextColor: '#737373',
      enrollRoundUpPlatformFeeText:
        'Would you like to help cover the platform fees so that more of your Round UPs go to this great cause?',
      dashboardImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/happy-kids+(1).png'
    },
    referralProgram: {
      referralTextPrimary:
        'Do you love LeasideLocal and doing good in your community as much as we do? Tell everyone you know! Simply copy your referral code below, and send it to all your friends and family or even share it on your social channels!',
      referralTextSecondary:
        'When you share your referral code with friends and family to join LeasideLocal you could be entered to win amazing gifts from our participating local Merchants and Booster Businesses!'
    },
    causeSetting: {
      title: 'Manage Your Charity',
      description:
        'If you would like to change the Charity you are currently rounding for, please select a new one below.'
    },
    userAvatar: {
      backgroundColor: '#0F0D0D',
      textColor: '#FFFFFF',
      mobileBackgroundColor: '#0F0D0D',
      mobileTextColor: '#FFFFFF'
    },
    searchLocations: {
      title: 'You Spend. They Donate.'
    },
    tab: {
      title: 'App | Hope Factory',
      icon: 'favicon-hopefactory'
    },
    pieChart: {
      primary: '#0081A4',
      secondary: '#D1C314',
      teritary: '#51949E'
    }
  },
  GenwellSales: {
    id: 'GenwellSales',
    name: 'Hope Factory',
    email: 'hello@hopefactory.ca',
    headerName: 'Round UP for GenWell Program',
    headerBackgroundImage: '/images/hopefactory-sales/main-image-secondharvest.png',
    headerBackgroundOverlay: null,
    iconAddress: '/images/hopefactory-sales/icon-address.svg',
    iconPhone: '/images/hopefactory-sales/icon-phone.svg',
    iconWeb: '/images/hopefactory-sales/icon-web.svg',
    partner: 'Leaside Life',
    text: {
      boosterCardAccent: '#0B4B9C !important',
      offerCardAccent: '#0B4B9C !important',
      commonHeaderTitle: 'Food Initiative',
      commonHeaderDescription: 'Shop local and help the Food Initiative',
      offersForYouDescription:
        'Our participating Merchants and Boosters understand the importance of human connection and the needs of society. The businesses below are pleased to support The GenWell Project’s efforts to build a more connected Canada.',
      offersForYouDescriptionColor: '#333',
      offersForYouDescriptionTitleColor: '#51AB9E',
      impactDashboardTitleColor: '#1D71C2',
      boostTitle: 'Boost Your Impact!',
      exploreOfferTitle: 'Explore Offers',
      exploreOfferDescription: 'Check out some of our LeasideLocal offers that you might not have seen yet!',
      impactDashboardDescription:
        'By participating in the Round UP for GenWell program, you are helping to build a more connected Canada by funding The GenWell Project’s efforts to educate, empower and catalyze forty million Canadians about the impacts of social isolation, disconnection and loneliness and the power of human connection to give all Canadians the opportunity to thrive',
      mySocialImpactDescription: 'Here’s how much you’ve helped by being a Human Connection Catalyst!',
      programSpendDescription:
        'Here’s how much you spent on Cash Forward offers, in support of local Merchants and The GenWell Project!'
    },
    links: {
      partner: null,
      facebook: 'https://www.facebook.com/thegenwellproject/',
      twitter: 'https://twitter.com/genwellproject?lang=en',
      instagram: 'https://www.instagram.com/genwellproject/?hl=en',
      privacyPolicy: 'https://hopefactory.net/privacy-policy/',
      termsConditions: 'https://hopefactory.net/terms-and-conditions/',
      faqs: 'https://hopefactory.net/faqs/',
      linkedin: 'https://www.linkedin.com/company/11523959',
      genWell: 'https://genwellproject.org/'
    },
    headerLogo: '/images/genwell-sales/genwell-logo.png',
    headerLogoSize: {
      height: '80',
      width: '215'
    },
    headerButtonColor: '#FFF',
    menuHeaderBackground: '#0B4B9C',
    menuHeaderTextColor: '#ffffff',
    offerCardHeaderPrimaryBackground: '#2C9CF2',
    offerCardHeaderSecondaryBackground: '#333',
    onboardImage: '/images/hopefactory-sales/onboard-secondharvest.png',
    onboardLayoutTitleText: 'Enrolling in the Round UP for GenWell program is easy and secure.',
    onboardLayoutTitleColor: '#FFF',
    onboardFirstStepTitleText: 'Create a Round UP for GenWell Program Account',
    onboardFirstStepDescriptionText: 'Create an account to explore the program.',
    onboardLayoutBackground: '#0B4B9C',
    onboardLayoutButtonBackground: '#82AED7 !important',
    onboardLayoutButtonBorder: null,
    onboardingStepColor: 'white',
    onboardLayoutMobileSetupTitle: 'Why do we need your Mobile Number?',
    onboardLayoutMobileSetupTextPrimary:
      'With your mobile number, we can send you messages instantly about how you’re making a difference and for important transactional information.',
    onboardLayoutMobileSetupTextSecondary:
      '(But if you really don’t want to include your phone number, no problem. This is optional.)',
    onboardLayoutMarketingText:
      'You agree to receive occasional notifications of new offers, or updates to the program or campaign, via the following channels. You can always update these preferences later in your settings. If you consent to us contacting you for this purpose, please select one or both of the communication methods below.',
    onboardLayoutMarketingTextSecondary:
      'The GenWell Project and Hope Factory are committed to protecting and respecting your privacy. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
    closingAccountText: 'Thorncliffe Food Bank and the Canada-Ukraine Foundation',
    yellowArrow: '/images/hopefactory-sales/yellow-arrow.svg',
    footerBackground: '#0B4B9C',
    background: {
      boosterCardAccent: '#0B4B9C'
    },
    blueTextColor: '#2C9CF2',
    roundUpBoosterCause: 'Round UP for GenWell Program',
    colorScheme: {
      dark: true,
      main: '#0B4B9C',
      secondary: '#51AB9E'
    },
    onboarding: {
      currentStepColor: '#FFF',
      pendingStepColor: '#82AED7'
    },
    appHeader: {
      upperHeaderImage: '/images/genwell-sales/upper-header.png',
      upperHeaderColor: '#82AED7',
      lowerHeaderImage: '/images/genwell-sales/genwell-logo.png',
      lowerHeaderHeight: 60,
      mobileHeaderImage: '/images/genwell-sales/hf-mobile.png',
      mobileHeaderSize: 70,
      barColor: '#1D71C2'
    },
    impactDashboard: {
      chartPrimaryColor: '#293C56',
      chartSecondaryColor: '#51AB9E'
    },
    offerCard: {
      buttonColor: '#2C9CF2'
    },
    roundUps: {
      arrowUpIconColor: '#2C9CF2',
      arrowUpIconColorSecondary: '#FFF',
      bannerColor: '#1D71C2 !important',
      progressBarColor: '#2C9CF2',
      textColor: '#333',
      titleColor: '#1D71C2',
      bannerText:
        'By opting into the Round UP for GenWell Program, you’ll be able to make an even bigger difference to the Human Connection Movement.',
      bannerTextSecondary: 'Every little bit gets us closer to a more connected Canada!',
      bannerTextColor: '#FFF',
      monthlyMinimumText: 'The Round UP for GenWell program monthly minimum is',
      enrollRoundUpTitle: 'Enroll in the GenWell Round UP Program',
      enrollRoundUpTitleColor: 'rgba(0, 0, 0, 0.87)',
      enrollRoundUpDescriptionText:
        'Our Round UP program lets you make a bigger impact by rounding up all purchases you make with your enrolled credit card and donating those funds to our Cause. This way, each time you spend for yourself, you are helping others too.',
      enrollRoundUpDescriptionTextColor: '#737373',
      enrollRoundUpPlatformFeeText:
        'Would you like to help cover the platform fees so that more of your Round UPs go to this great cause?',
      dashboardImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/happy-kids+(1).png'
    },
    referralProgram: {
      referralTextPrimary:
        'Do you support The GenWell Project’s work on building a more connected Canada and love making a positive impact as much as we do? Tell everyone you know! Simply copy your referral code below, and send it to all your friends and family or even share it on your social channels!'
    },
    causeSetting: {
      title: 'Manage Your Charity',
      description:
        'If you would like to change the Charity you are currently rounding for, please select a new one below.'
    },
    userAvatar: {
      backgroundColor: '#EAFAFC',
      textColor: '#333',
      mobileBackgroundColor: '#0B4B9C',
      mobileTextColor: '#FFF'
    },
    searchLocations: {
      title: 'You Spend. We Connect.'
    },
    tab: {
      title: 'App | The GenWell Project',
      icon: 'favicon-hopefactory'
    },
    pieChart: {
      primary: '#2C9CF2',
      secondary: '#0B4B9C',
      teritary: '#82AED7'
    }
  },
  HopefactorySalesUSA: {
    id: 'HopefactorySales',
    name: 'Thorncliffe Food Bank',
    email: 'hello@hopefactory.ca',
    headerName: 'Hope Factory',
    headerBackgroundImage: '/images/hopefactory-sales/main-image-secondharvest.png',
    headerBackgroundOverlay: null,
    iconAddress: '/images/hopefactory-sales/icon-address.svg',
    iconPhone: '/images/hopefactory-sales/icon-phone.svg',
    iconWeb: '/images/hopefactory-sales/icon-web.svg',
    partner: 'Leaside Life',
    text: {
      boosterCardAccent: '#0F0D0D !important',
      offerCardAccent: '#0F0D0D !important',
      offersForYouDescription:
        'These great businesses are listed only for the purpose of demonstrating Hope Factory capabilities. No money will be donated by these businesses or other organizations. For Demo Purposes Only!',
      offersForYouDescriptionColor: '#131315',
      offersForYouDescriptionTitleColor: '#51AB9E',
      impactDashboardTitleColor: '#0F0D0D',
      exploreOfferTitle: 'Explore Offers',
      exploreOfferDescription: 'Check out some of our LeasideLocal offers that you might not have seen yet!', //x
      impactDashboardDescription:
        'By participating in Hope Factory, you are helping support important causes and charities, as well as local Merchants in your community!',
      mySocialImpactDescription:
        'Here’s how much you’ve helped by participating in the Hope Factory program.',
      programSpendDescription:
        'Here’s how much you spent on Hope Factory offers, in support of local Merchants, through the program!'
    },
    links: {
      partner: null,
      facebook: 'https://www.facebook.com/wearethehopefactory',
      twitter: 'https://twitter.com/leasidelife',
      instagram: 'https://www.instagram.com/wearethehopefactory/',
      privacyPolicy: 'https://hopefactory.net/privacy-policy',
      termsConditions: 'https://hopefactory.net/terms-and-conditions/',
      hopeFactory: 'https://www.hopefactory.net/',
      faqs: 'https://hopefactory.net/faqs/'
    },
    headerLogo: '/images/hopefactory-sales/hf-logo.svg',
    headerLogoSize: {
      height: '90',
      width: '215'
    },
    headerButtonColor: '#f5f5f5',
    menuHeaderBackground: '#0F0D0D',
    menuHeaderTextColor: '#ffffff',
    offerCardHeaderPrimaryBackground: '#51AB9E',
    offerCardHeaderSecondaryBackground: '#0F0D0D',
    onboardImage: '/images/hopefactory-sales/onboard-secondharvest.png',
    onboardLayoutTitleText: 'Enrolling in the Hope Factory program is easy and secure.',
    onboardLayoutTitleColor: '#131315',
    onboardFirstStepTitleText: 'Create a Hope Factory Account',
    onboardFirstStepDescriptionText: 'Create an account to explore the Hope Factory program.',
    onboardLayoutBackground: '#7CB81B',
    onboardLayoutButtonBackground: '#0F0D0D !important',
    onboardLayoutButtonBorder: null,
    onboardLayoutMobileSetupTitle: 'Why give us your mobile number?',
    onboardLayoutMobileSetupTextPrimary:
      'We can send you transactional SMS messages about your Hope Factory program activity including updates on your monthly Round UPs and Offer redemptions, so you instantly know the difference you’re making in your community.',
    onboardLayoutMobileSetupTextSecondary: 'This is optional and you can opt out at any time.',
    onboardLayoutMarketingText:
      'You agree to receive occasional notifications of new offers, or updates to the program or campaign, via the following channels. You can always update these preferences later in your settings. If you consent to us contacting you for this purpose, please select one or both of the communication methods below.',
    onboardLayoutMarketingTextSecondary:
      'Hope Factory is committed to protecting and respecting your privacy. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
    yellowArrow: '/images/hopefactory-sales/yellow-arrow.svg',
    footerBackground: '#0F0D0D',
    background: {
      boosterCardAccent: '#0F0D0D'
    },
    blueTextColor: '#1181C8',
    roundUpBoosterCause: 'The Leaside Toy Drive',
    colorScheme: {
      dark: true,
      main: '#0F0D0D'
    },
    onboarding: {
      currentStepColor: '#0F0D0D',
      pendingStepColor: '#FFFFFF'
    },
    appHeader: {
      upperHeaderImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/us-demo.png',
      upperHeaderColor: '#7CB81B',
      lowerHeaderImage: '/images/hopefactory-sales/hf-logo.svg',
      lowerHeaderHeight: 25,
      mobileHeaderImage: '/images/presented-by-hf-sales.png',
      mobileHeaderSize: 70,
      barColor: '#000'
    },
    offerCard: {
      buttonColor: '#51AB9E'
    },
    roundUps: {
      arrowUpIconColor: '#7CB81B',
      arrowUpIconColorSecondary: '#FFFFFF',
      bannerColor: '#51AB9E !important',
      progressBarColor: '#7CB81B',
      textColor: '#0F0D0D',
      titleColor: '#51AB9E',
      bannerText:
        'By opting into the Round UP program you will be able to make an even bigger difference in your community.',
      bannerTextSecondary: 'Every little bit helps!',
      bannerTextColor: '#FFF',
      enrollRoundUpTitle: 'Enroll in the Hope Factory Round UP Program',
      enrollRoundUpTitleColor: 'rgba(0, 0, 0, 0.87)',
      enrollRoundUpDescriptionText:
        'Our Round UP program lets you make a bigger impact by rounding up all purchases you make with your enrolled credit card and donating those funds to our Cause. This way, each time you spend for yourself, you are helping others too.',
      enrollRoundUpDescriptionTextColor: '#737373',
      enrollRoundUpPlatformFeeText:
        'Would you like to help cover the platform fees so that more of your Round UPs go to this great cause?',
      dashboardImage:
        'https://hope-factory-production.s3.us-east-2.amazonaws.com/hopefactorySales/happy-kids+(1).png'
    },
    referralProgram: {
      referralTextPrimary:
        'Do you love LeasideLocal and doing good in your community as much as we do? Tell everyone you know! Simply copy your referral code below, and send it to all your friends and family or even share it on your social channels!',
      referralTextSecondary:
        'When you share your referral code with friends and family to join LeasideLocal you could be entered to win amazing gifts from our participating local Merchants and Booster Businesses!'
    },
    causeSetting: {
      title: 'Manage Your Charity',
      description:
        'If you would like to change the Charity you are currently rounding for, please select a new one below.'
    },
    userAvatar: {
      backgroundColor: '#0F0D0D',
      textColor: '#FFFFFF',
      mobileBackgroundColor: '#0F0D0D',
      mobileTextColor: '#FFFFFF'
    },
    searchLocations: {
      title: 'You Spend. They Donate.'
    },
    tab: {
      title: 'App | Hope Factory',
      icon: 'favicon-hopefactory'
    },
    pieChart: {
      primary: '#0081A4',
      secondary: '#D1C314',
      teritary: '#51949E'
    }
  }
};
