import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// Utilities
import store from '@/store';

// Types
import { IRoundUpStats, IRoundUpRuleResponse, IRoundUpRuleGlobalSettings } from '@/types/round-up.types';

@Module({ dynamic: true, name: 'round_up', namespaced: true, store })
class RoundUpState extends VuexModule {
  globalSettings: IRoundUpRuleGlobalSettings = {
    ecosystemMinimum: 0
  };
  roundUp: IRoundUpRuleResponse | null = null;
  roundUpStats: IRoundUpStats = {
    carryOverAmount: 0,
    roundUpAmount: 0,
    roundUpTotalAmount: 0,
    roundUpYearToDate: 0
  };

  /** Retrieve ecosystem fee */
  get getEcosystemFee(): number | undefined {
    return this.globalSettings.ecosystemFee;
  }

  /** Retrieve ecosystem round up minimum */
  get getEcosystemMinimum(): number {
    return this.globalSettings.ecosystemMinimum;
  }

  /** Retrieve round up rule */
  get getRoundUp(): IRoundUpRuleResponse | null {
    return this.roundUp;
  }

  /** Retrieve round up stats */
  get getRoundUpStats(): IRoundUpStats {
    return this.roundUpStats;
  }

  /** Initialize round up to default state */
  @Action
  initialize(): void {
    this.setGlobalSettings({
      ecosystemMinimum: 0
    });
    this.setRoundUp(null);
    this.setRoundUpStats({
      carryOverAmount: 0,
      roundUpAmount: 0,
      roundUpTotalAmount: 0,
      roundUpYearToDate: 0
    });
  }

  @Mutation
  setGlobalSettings(globalSettings: IRoundUpRuleGlobalSettings): void {
    this.globalSettings = globalSettings;
  }

  /**
   * Set round up rule
   *
   * @param roundUp     - Round up object or null
   */
  @Mutation
  setRoundUp(roundUp: IRoundUpRuleResponse | null): void {
    this.roundUp = roundUp;
  }

  /**
   * Set round up stats
   *
   * @param roundUpStats     - Round up stats
   */
  @Mutation
  setRoundUpStats(roundUpStats: IRoundUpStats): void {
    this.roundUpStats = roundUpStats;
  }
}

export default getModule(RoundUpState);
