
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';

// Utilities
import { mdiCheckCircle, mdiUnfoldMoreHorizontal } from '@mdi/js';
import { BrandService, TransactionService } from '@/services';
import { FeatureModule } from '@/store/modules';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Types
import { ISelectionItem, ITableHeader, ITimePeriod } from '@/types/base.types';
import { ITransactionResponse, TransactionType } from '@/types/transaction.types';

@Component
export default class RoundUpPaymentHistory extends Vue {
  currency!: any;
  dayjs!: any;

  currentTenant = tenants[ActiveTenant];
  titleColor = this.currentTenant.roundUps.titleColor;

  isOffersEnabled = FeatureModule.getIsOffersEnabled;
  headers = this.isOffersEnabled
    ? [
        { text: 'DATE', value: 'purchaseDateTime' },
        { text: 'MERCHANT', value: 'brandName' },
        { sortable: false, text: 'PURCHASE AMOUNT', value: 'amount' },
        { sortable: false, text: 'CASH FORWARD', value: 'totalRewardAmount' },
        { sortable: false, text: 'STATUS', value: 'redeemedOfferId' }
      ]
    : [
        { text: 'DATE', value: 'purchaseDateTime' },
        { text: 'MERCHANT', value: 'brand.name' },
        { sortable: false, text: 'PURCHASE AMOUNT', value: 'amount' },
        { sortable: false, text: 'STATUS', value: 'redeemedOfferId' }
      ];

  icons = {
    mdiCheckCircle,
    mdiUnfoldMoreHorizontal
  };

  // Computed dynamic header - only for mobile it will switch the first column position according to the item being sorted
  get computedHeaders(): ITableHeader[] {
    if (this.$vuetify.breakpoint.name !== 'xs') return this.headers;
    const dynamicHeaders = this.headers.filter((header: ITableHeader) => header.value !== this.sortBy[0]);
    const sortHeader: any = this.headers.find((header: ITableHeader) => header.value === this.sortBy[0]);
    dynamicHeaders.splice(0, 0, sortHeader);
    return dynamicHeaders;
  }

  loading = false;
  options = {
    itemsPerPage: 50,
    page: 1
  };
  sortBy = ['purchaseDateTime'];
  sortDesc = true;
  totalItems = 0;
  transactions: ITransactionResponse[] = [];

  brandSelections: ISelectionItem[] = [{ text: 'All Merchants', value: null }];
  selectedBrand: string | null = null;

  selectionsPeriod = [
    { text: 'All Time', value: { startDate: undefined, endDate: undefined } },
    {
      text: 'Last 30 days',
      value: { startDate: dayjs().subtract(30, 'day').startOf('day').toDate(), endDate: dayjs().toDate() }
    },
    {
      text: 'Last 90 days',
      value: { startDate: dayjs().subtract(90, 'day').startOf('day').toDate(), endDate: dayjs().toDate() }
    },
    {
      text: 'This year',
      value: { startDate: dayjs().startOf('year').toDate(), endDate: dayjs().toDate() }
    },
    {
      text: 'Last year',
      value: {
        startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
        endDate: dayjs().subtract(1, 'year').endOf('year').toDate()
      }
    }
  ];
  selectedPeriod: ITimePeriod = this.selectionsPeriod[1].value;

  selectedTransactionType: TransactionType | null = null;
  transactionTypeSelections = [
    { text: 'All Transaction Types', value: null },
    { text: 'Cash Forward', value: TransactionType.CASH_FORWARD }
  ];

  onSelectBrand(selected: string | null): void {
    this.options.page = 1;
    this.selectedBrand = selected;
    this.getTransactions();
  }

  onSelectPeriod(selected: ITimePeriod): void {
    this.options.page = 1;
    this.selectedPeriod = selected;
    this.getTransactions();
  }

  onSelectTransactionType(selected: TransactionType | null): void {
    this.options.page = 1;
    this.selectedTransactionType = selected;
    this.getTransactions();
  }

  created(): void {
    this.getTransactions();
    this.getBrands();
  }

  async getBrands(): Promise<void> {
    const brands = await BrandService.getTransactionBrands();
    this.brandSelections.push(...brands.map((brand) => ({ text: brand.name, value: brand.id })));
  }

  async getTransactions(): Promise<void> {
    // NOTE: When user changes sort option - sortDesc
    // doesn't change immediately. Had to add a timeout here
    // to make sure it updates before we make a call to api.
    setTimeout(async () => {
      this.loading = true;
      try {
        const response = await TransactionService.getTransactions({
          brandId: this.selectedBrand,
          paginationParams: this.options,
          period: this.selectedPeriod,
          sort: (this.sortDesc ? '-' : '') + this.sortBy[0],
          type: this.selectedTransactionType
        });
        this.transactions = response.data;
        this.totalItems = response.pagination.totalItems;
      } catch (err) {
        this.$notifyError("Couldn't load payment history");
      }
      this.loading = false;
    }, 10);
  }
}
