
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

// Types
import { ITimePeriod } from '@/types/base.types';

@Component({})
export default class PeriodSelector extends Vue {
  @Prop() onSelect!: (period: ITimePeriod) => void;
  @Prop({ default: 'max-w-200' }) maxWidthClass!: string;

  selectedItem = { startDate: undefined, endDate: undefined };
  selections = [
    { text: 'All Time', value: { startDate: undefined, endDate: undefined } },
    {
      text: 'Last 3 months',
      value: { startDate: dayjs().subtract(2, 'month').startOf('month').toDate(), endDate: dayjs().toDate() }
    },
    {
      text: 'Last 6 months',
      value: { startDate: dayjs().subtract(5, 'month').startOf('month').toDate(), endDate: dayjs().toDate() }
    },
    {
      text: 'This year',
      value: { startDate: dayjs().startOf('year').toDate(), endDate: dayjs().endOf('year').toDate() }
    },
    {
      text: 'Last year',
      value: {
        startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
        endDate: dayjs().subtract(1, 'year').endOf('year').toDate()
      }
    }
  ];

  async itemSelected(): Promise<void> {
    this.onSelect(this.selectedItem);
  }
}
