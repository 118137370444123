const AdminLogin = () => import('./AdminLogin.vue');
const AdminOffers = () => import('./AdminOffers.vue');
const AdminBrands = () => import('./AdminBrands.vue');
const AdminCorporates = () => import('./AdminCorporates.vue');
const AdminReports = () => import('./AdminReports.vue');
const GlobalSettings = () => import('./GlobalSettings.vue');
const AdminSettings = () => import('./AdminSettings.vue');
const AdminCauses = () => import('./AdminCauses.vue');

export {
  AdminLogin,
  AdminOffers,
  AdminBrands,
  AdminCorporates,
  GlobalSettings,
  AdminReports,
  AdminSettings,
  AdminCauses
};
