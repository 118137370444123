// Utilities
import { ApiService } from '@/services';
import { OfferModule } from '@/store/modules';
import { IBasePaged, IPaginationParams } from '@/types/base.types';
import { IOfferFilter, OfferDetail, IBoosters } from '@/types/offer.types';
import { IPagedOffersTransactions } from '@/types/redeemed-offers.types';

class OffersService {
  /**
   * Fetch olive offers
   *
   * @returns olive offers
   */
  async fetchOffers(filter?: IOfferFilter): Promise<IBasePaged<OfferDetail>> {
    if (!filter) {
      filter = {
        pageNumber: 1,
        pageSize: 20
      };
    }
    const response = await ApiService.api.get('/offers', { params: filter });
    const offers = response.data;

    return offers;
  }

  getOffers(): OfferDetail[] | undefined {
    return OfferModule.getOffers;
  }

  async fetchRedeemedOffersTransactions(params?: IPaginationParams): Promise<IPagedOffersTransactions> {
    if (!params) {
      params = {
        pageNumber: 1,
        // TODO: pagination does not exist in 'myOffers' tab. This is a temporary fix until we get the pagination working.
        pageSize: 100
      };
    }
    const response = await ApiService.api.get('/members/transactions/offers', {
      params
    });
    return response.data;
  }

  async fetchBoosters(): Promise<IBoosters[]> {
    const response = await ApiService.api.get('/matchings/boosters');
    const boosters = response.data;

    return boosters;
  }
}

const singleton = new OffersService();
export default singleton;
