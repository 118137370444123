// Utilities
import { AuthModule, FeatureModule, RoundUpModule, UserModule } from '@/store/modules';
import PageNotFound from '@/views/PageNotFound.vue';
import anonymousRoutes from './routes-anonymous';
import securedRoutes from './routes-secured';

export const routes = [
  ...anonymousRoutes,
  ...securedRoutes,
  {
    path: '*',
    name: 'pageNotFound',
    component: PageNotFound
  }
];

export const getHomeRoute = (): string => {
  if (!AuthModule.isAuthenticated) {
    return '/register';
  }
  if (FeatureModule.getIsOffersEnabled) {
    return '/offers';
  }
  if (RoundUpModule.getRoundUp) {
    if (UserModule.authenticated && UserModule.userRole === 'admin') {
      return '/admin/global-settings';
    } else {
      return '/dashboard';
    }
  }
  return '/settings/round-up-program';
};
