// Utilities
import { version } from '../package.json';

const apiUrl = process.env.VUE_APP_API_URL;
if (!apiUrl) {
  throw new Error('API url is missing!');
}

const olivePublicKey = process.env.VUE_APP_OLIVE_PUBLIC_KEY;
if (!olivePublicKey) {
  throw new Error('Olive Public Key is missing!');
}

const oliveCardSdk = process.env.VUE_APP_OLIVE_CARD_SDK;
if (!oliveCardSdk) {
  throw new Error('Url for Olive Card SDK is missing!');
}

const activeProject = process.env.VUE_APP_ACTIVE_TENANT;
if (!activeProject) {
  throw new Error('Active Project Key is missing!');
}

const webConfig = {
  api: {
    url: apiUrl,
    olivePublicKey,
    oliveCardSdk
  },
  app: {
    envName: process.env.VUE_APP_ENV_NAME ?? 'local',
    production: process.env.NODE_ENV === 'production',
    version,
    activeProject
  }
};

export default webConfig;
