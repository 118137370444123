
import Vue from 'vue';
import Component from 'vue-class-component';
import { TheAppFooter, TheAppActiveTenantFooter } from '@/components/layout';
import TheAppNotification from '@/components/TheAppNotification.vue';
import { AuthService, FeatureService, RoundUpService } from '@/services';

import AppLoader from '@/components/common/AppLoader.vue';

// Config Variables
import { ActiveTenant } from '../active-tenant';
import AppHeader from './components/layout/AppHeader.vue';
import { tenants } from '../tenant-config';

@Component({
  components: {
    AppHeader,
    TheAppFooter,
    AppLoader,
    TheAppNotification,
    TheAppActiveTenantFooter
  }
})
export default class App extends Vue {
  /** Whether authentication is loading (MUST start 'true' to avoid double mounting components!) */
  loadingAuth = true;
  icon = require(`../public/${tenants[ActiveTenant].tab.icon}.png`);
  activeTenant = ActiveTenant;

  /** Whether initial app data is loading */
  get loading(): boolean {
    return this.loadingAuth;
  }

  async mounted(): Promise<void> {
    await this.getHeaderImages();

    const title = tenants[ActiveTenant].tab.title;
    window.document.title = title;
    const icon = document.createElement('link');
    icon.rel = 'icon';
    icon.href = this.icon;
    document.getElementsByTagName('head')[0].appendChild(icon);

    await this.loadUser();
    if (AuthService.fetchUser) {
      FeatureService.getFeatureStatuses();
      RoundUpService.getRule();
      RoundUpService.getRoundUpStats();
    }
  }

  /**
   * Fetch the authenticated user (if any)
   *
   * @returns Whether authenticated user loaded successfully
   */
  async loadUser(): Promise<void> {
    const fetch = AuthService.fetchUser;

    if (fetch === null) {
      this.loadingAuth = false;
      this.preventRouteAccess();
      return;
    }

    this.loadingAuth = true;

    let user = await fetch;

    this.loadingAuth = false;

    if (!user) return;

    // Redirect away from unauthenticated pages when authenticated
    const { meta } = this.$route;
    if (meta && meta.requiresNoAuth) {
      this.$router.replace('/');
    }
  }

  /**
   * Protect authorized routes if user authentication fails
   */
  preventRouteAccess(): void {
    const { fullPath, matched } = this.$route;

    // Redirect away from authenticated pages if the viewer authentication fails.
    const requiresAuth = matched?.some((m) => m.meta?.requiresAuth);
    if (requiresAuth) {
      this.$router.replace({
        path: '/login',
        query: { redirectUrl: fullPath }
      });

      this.$notifyError('User is not authenticated');
    }
  }

  /** Fetch header images */
  async getHeaderImages(): Promise<void> {
    try {
      await FeatureService.fetchLayoutSettingsImages();
    } catch (e: any) {
      this.$notifyError('Something went wrong during header image fetch');
    }
  }
}
