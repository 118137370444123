import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// Utilities
import config from '@/config';
import store from '@/store';
import { IBasePaged } from '@/types/base.types';
import { OfferDetail, IBoosters } from '@/types/offer.types';

// Types
// import { IAuthUser } from '@/types/user.types';

const moduleName = 'offers';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!config.production && store.state[moduleName]) {
  store.unregisterModule(moduleName);
}

@Module({ dynamic: true, name: moduleName, namespaced: true, store })
class OffersState extends VuexModule {
  offers: OfferDetail[] = [];
  allBoosters: IBoosters[] = [];
  savedResponse!: IBasePaged<OfferDetail>;
  redeemedOffers: IBasePaged<OfferDetail> | null = null;

  selectedOffer: OfferDetail | undefined;
  activeTab = 'offers';
  offerType = '';

  /** Get active tab */
  get getActiveTab(): string | null {
    return this.activeTab;
  }

  /** Get saved response offers */
  get getSavedResponseOffers(): IBasePaged<OfferDetail> {
    return this.savedResponse;
  }

  /** Get Offers */
  get getOffers(): OfferDetail[] {
    return this.offers;
  }

  /** Get My Offers */
  get getRedeemedOffers(): IBasePaged<OfferDetail> | null {
    return this.redeemedOffers;
  }

  /** Get single offer */
  get getSelectedOffer(): OfferDetail | undefined {
    return this.selectedOffer;
  }

  /** Get Boosters */
  get getBoosters(): IBoosters[] {
    return this.allBoosters;
  }

  /**
   * Initialize state
   */
  @Mutation
  initialize(): void {
    this.offers = [];
    this.redeemedOffers = null;
  }

  /**
   * Set offer response
   */
  @Mutation
  setSavedOffers(responsedOffers: IBasePaged<OfferDetail>): void {
    this.savedResponse = responsedOffers;
  }

  /**
   * Set boosters
   */
  @Mutation
  setBoosters(boostersResponse: IBoosters[]): void {
    this.allBoosters = boostersResponse;
  }

  /**
   * Set single offer active
   */
  @Mutation
  selectOffer(id: string): void {
    if (this.offers && this.offers.length) {
      this.selectedOffer = this.offers.find((x) => x.id === id);
    }
  }

  /**
   * Set the Offers
   *
   * @param offers - Olive Offers
   */
  @Mutation
  setOffers(offers: OfferDetail[]): void {
    this.offers = offers;
  }

  /**
   * Append the Offers
   *
   * @param offers - Olive Offers
   */
  @Mutation
  appendOffers(offers: OfferDetail[]): void {
    this.offers.push(...offers);
  }

  /**
   * Set the my Offers
   *
   * @param offers - Olive Offers
   */
  @Mutation
  setRedeemedOffers(offers: IBasePaged<OfferDetail>): void {
    this.redeemedOffers = offers;
  }

  /**
   * Set active offers tab
   *
   * @param tab - Current active tab
   */
  @Mutation
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  /**
   * Clear the Offers
   */
  @Mutation
  removeOffers(): void {
    this.offers = [];
  }
}

export { OffersState };
export default getModule(OffersState);
