const getDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  const convertedDate = date.toUTCString();
  const dateArr = convertedDate.split(' ');
  const completedDate = `${dateArr[2]} ${dateArr[1]}`;
  return completedDate;
};

const getShortDate = (inputDate: string, isMonthNeed?: boolean): string => {
  const date = new Date(inputDate);
  const convertedDate = date.toUTCString();
  const dateArr = convertedDate.split(' ');
  let completedDate = '';

  if (isMonthNeed) {
    completedDate = `${dateArr[2]} ${dateArr[1]}`;
  } else {
    completedDate = `${dateArr[1]}`;
  }
  return completedDate;
};

const getFormattedDate = (startDate: string, endDate: string, type?: string): string => {
  let processedStartDate: any;
  let processedEndDate: any;

  if (type === 'short') {
    processedStartDate = getShortDate(startDate, true);
    processedEndDate = getShortDate(endDate);
  } else {
    processedStartDate = getDate(startDate);
    processedEndDate = getDate(endDate);
  }
  const completedDate = `${processedStartDate} - ${processedEndDate}`;
  return completedDate;
};

export const getBgColor = (title: string): string => {
  let color = '';

  if (title === 'Cash Back') {
    color = '$color-primary';
  } else if (title === 'Cash Forward') {
    color = '#007043';
  } else if (title === 'Boost') {
    color = '#792DDA';
  }

  return color;
};

interface BackgroundImages {
  [key: string]: string;
}

export const getLayoutData = (): BackgroundImages => {
  const headerImages = localStorage.getItem('headerImages');
  let headerImagesParsed;
  if (headerImages) {
    headerImagesParsed = JSON.parse(headerImages);
  }

  return {
    background: headerImagesParsed?.background ?? require(`@/assets/images/background.png`),
    overlay: headerImagesParsed?.overlay ?? require(`@/assets/images/overlay.png`),
    banner: headerImagesParsed?.banner ?? require(`@/assets/images/banner-logo.png`),
    onboardBackground: headerImagesParsed?.onboardBackground ?? require(`@/assets/images/background.png`),
    onboardOverlay: headerImagesParsed?.onboardOverlay ?? require(`@/assets/images/overlay.png`),
    onboardBanner: headerImagesParsed?.onboardBanner ?? require(`@/assets/images/banner-logo.png`),
    icon1: headerImagesParsed?.firstIcon ?? '',
    link1: headerImagesParsed?.firstLink ?? '',
    icon2: headerImagesParsed?.secondIcon ?? '',
    link2: headerImagesParsed?.secondLink ?? '',
    icon3: headerImagesParsed?.thirdIcon ?? '',
    link3: headerImagesParsed?.thirdLink ?? '',
    icon4: headerImagesParsed?.fourthIcon ?? '',
    link4: headerImagesParsed?.fourthLink ?? '',
    footerText: headerImagesParsed?.footerText ?? ''
  };
};

export default getFormattedDate;
