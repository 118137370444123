// Utilities
import { ApiService } from '@/services';
import { IBoosters } from '@/types/offer.types';

class MatchingService {
  async fetchBoosters(): Promise<IBoosters[]> {
    const response = await ApiService.api.get('/matchings/boosters', {
      params: { matchingType: 'rounding' }
    });

    const roundingBoosts = response.data;

    return roundingBoosts;
  }
}

const singleton = new MatchingService();
export default singleton;
