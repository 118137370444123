import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// Utilities
import config from '@/config';
import store from '@/store';

// Types
import { IAuthTokens } from '@/types/auth.types';

const moduleName = 'auth';
// @ts-ignore
if (!config.production && store.state[moduleName]) {
  store.unregisterModule(moduleName);
}

@Module({ dynamic: true, name: moduleName, namespaced: true, store })
class AuthState extends VuexModule {
  /** Authentication tokens */
  auth: IAuthTokens | null = null;

  /** Tokens */
  get tokens(): IAuthTokens | null {
    return this.auth;
  }

  get isAuthenticated(): boolean {
    return Boolean(this.auth);
  }

  /** Initialize state */
  @Mutation
  initialize(): void {
    this.auth = null;
  }

  /**
   * Set the Authentication token
   *
   * @param auth - Authentication token
   */
  @Mutation
  setTokens(auth: IAuthTokens): void {
    this.auth = auth;
  }

  /**
   * Clear the Authentication token
   */
  @Mutation
  removeTokens(): void {
    this.auth = null;
  }
}

export { AuthState };
export default getModule(AuthState);
