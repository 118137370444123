// Types
import { IPaginationParamsVDataTable, ITimePeriod } from './base.types';
import { IBrandResponse } from './brand.types';

export interface IGetTransactionRequest {
  // Filter param for ID of the brand in whose store transaction occurred
  brandId?: string | null;
  // Pagination params specifying page size and number
  paginationParams: IPaginationParamsVDataTable;
  // Filter for specifying time frame when transactions ocurred
  period?: ITimePeriod;
  // String for specifying sort parameters
  sort?: string;
  // Filter param for type of transaction reward
  type?: TransactionType | null;
}

export interface IMatchingRedemptionResponse {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  matchingId: string;
  matchingValue: number;
  transactionId: string;
}

export interface INumberOfTransaction {
  currentMonth: number;
  currentYear: number;
}

export interface ITransactionResponse {
  id: string;
  amount: number | null;
  currencyCode: string;
  purchaseDateTime: Date;
  rewardAmount: number | null;
  redeemedOfferId: string | null;
  // Sum of reward amount amd redeemed matching matching values
  totalRewardAmount: number | null;
  brand?: IBrandResponse;
  matchingRedemptions: IMatchingRedemptionResponse[];
}

export enum TransactionType {
  CASH_BACK = 'cash_back',
  CASH_FORWARD = 'cash_forward',
  POINTS = 'points'
}
