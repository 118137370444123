// Utilities
import { ApiService } from '@/services';
import { FeatureModule } from '@/store/modules';
import { IAdminSettingsImagesResponse, IMultipleCauseEnabled } from '@/types/admin.types';

// Types
import { IFeaturesStatuses } from '@/types/feature.types';

class FeatureService {
  async getFeatureStatuses(): Promise<IFeaturesStatuses> {
    const { data } = await ApiService.api.get('/feature');
    const result = {
      isEcosystemFeeEnabled: data.isEcosystemFeeEnabled,
      isOffersEnabled: data.isOffersEnabled,
      isRoundUpEnabled: data.isRoundUpEnabled,
      isMultipleCauseEnabled: data.isMultipleCauseEnabled
    };

    FeatureModule.setFeatureStatuses(result);
    return data;
  }

  /**
   * Get Layout Images
   *
   * @returns   Background, Overlay, and Banner image
   */
  async fetchLayoutSettingsImages(): Promise<IAdminSettingsImagesResponse> {
    const response = await ApiService.api.get('/feature/layout');
    if (response.data) {
      const headerImagesJSON = JSON.stringify(response.data);
      localStorage.setItem('headerImages', headerImagesJSON);
    }
    return response.data;
  }

  async getIsMultipleCauseEnabled(): Promise<IMultipleCauseEnabled> {
    const { data } = await ApiService.api.get('/feature/multiple-cause');
    return data;
  }
}

const singleton = new FeatureService();
export default singleton;
