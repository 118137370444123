const AccountVerification = () => import('./AccountVerification.vue');
const Login = () => import('./Login.vue');
const Logout = () => import('./Logout.vue');
const Register = () => import('./Register.vue');
const ResetPassword = () => import('./ResetPassword.vue');
const EmailVerification = () => import('./EmailVerification.vue');
const EditEmail = () => import('./EditEmail.vue');
const ForgotPassword = () => import('./ForgotPassword.vue');

export {
  AccountVerification,
  Login,
  Logout,
  Register,
  ResetPassword,
  EmailVerification,
  EditEmail,
  ForgotPassword
};
