// Utilities
import { ApiService, AuthService } from '@/services';

// Types
import {
  IAdminCorporate,
  IAdminOffer,
  IBrands,
  IEmailCustomResponse,
  IGlobalSettings,
  IGlobalSettingUpdate,
  IOfferRedeemedRequest,
  IPaymentChargeRequest,
  ISmsCustomResponse,
  ITestEmailPayload,
  ITestSmsPayload
} from '@/types/admin.types';
import { IAuthCredentials, IAuthTokens } from '@/types/auth.types';
import { ICauseCategorySettings, ICauseSettings } from '@/types/cause.types';

// NOTE: We're retrieving records of 1000 in here. Ideally we'd want to have pagination implemented on front end
// and retrieve by smaller chunks. But for now retrieving everything in 1 big chunk is fine and 1000 records should
// be sufficient to retrieve everything
class AdminService {
  async fetchBrands(): Promise<IBrands[]> {
    const filter = {
      limit: 1000,
      page: 1
    };
    const response = await ApiService.api.get('/admin/brand', {
      params: filter
    });

    const brands = response.data.data;
    return brands;
  }

  async fetchAdminOffers(): Promise<IAdminOffer[]> {
    const filter = {
      limit: 1000,
      page: 1
    };

    const response = await ApiService.api.get('/admin/offer', {
      params: filter
    });

    const adminOffers = response.data.data;
    return adminOffers;
  }

  async fetchAdminCorporates(): Promise<IAdminCorporate[]> {
    const filter = {
      limit: 1000,
      page: 1
    };

    const response = await ApiService.api.get('/admin/corporate', {
      params: filter
    });

    const adminCorporates = response.data.data;
    return adminCorporates;
  }

  /**
   * Get global settings
   *
   * @returns   Global settings
   */
  async fetchGlobalSettings(): Promise<IGlobalSettings[]> {
    const response = await ApiService.api.get('/admin/global-settings');
    return response.data;
  }

  /**
   * Get Cause Categories
   *
   * @returns   cause categories settings
   */
  async fetchCauseCategories(sort?: string): Promise<ICauseCategorySettings[]> {
    const response = await ApiService.api.get('/cause-category', { params: { sort } });
    return response.data;
  }

  /**
   * Create cause category
   * @param formData - category data to be created
   * @returns - created category
   */
  async createCauseCategory(formData: FormData): Promise<ICauseCategorySettings> {
    const response = await ApiService.api.post('/cause-category', formData);
    return response.data;
  }

  /**
   * Update cause category
   * @param id - cause category id
   * @param formData - changes to make
   * @returns - updated category
   */
  async updateCauseCategory(id: string, formData: FormData): Promise<ICauseCategorySettings> {
    const response = await ApiService.api.patch(`/cause-category/${id}`, formData);
    return response.data;
  }

  /**
   * Delete cause category
   * @param id - cause category id
   * @returns - deleted category
   */
  async deleteCauseCategory(id: string): Promise<void> {
    const response = await ApiService.api.delete(`/cause-category/${id}`);
    return response.data;
  }

  /**
   * Get Causes
   *
   * @returns   causes settings
   */
  async fetchCauses(categoryId?: string, causeId?: string): Promise<ICauseSettings[]> {
    const response = await ApiService.api.get('/causes', { params: { categoryId, causeId } });
    return response.data;
  }

  /**
   * Create cause
   * @param formData - cause data to be created
   * @returns - created cause
   */
  async createCause(formData: FormData): Promise<ICauseSettings> {
    const response = await ApiService.api.post('/causes', formData);
    return response.data;
  }

  /**
   * Update cause
   * @param id - cause id
   * @param formData - changes to make
   * @returns - updated cause
   */
  async updateCause(id: string, formData: FormData): Promise<ICauseSettings> {
    const response = await ApiService.api.patch(`/causes/${id}`, formData);
    return response.data;
  }

  /**
   * Delete cause
   * @param id - cause id
   * @returns - deleted cause
   */
  async deleteCause(id: string): Promise<void> {
    const response = await ApiService.api.delete(`/causes/${id}`);
    return response.data;
  }

  /**
   * Get Charges
   *
   * @returns csv file with charges
   */
  async fetchCharges(payload: IPaymentChargeRequest) {
    const response = await ApiService.api.get('/admin/round-up/charges', {
      params: payload,
      responseType: 'blob'
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'charges.csv');
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /**
   * Log admin in via credentials
   *
   * @param credentials - Admin credentials
   */
  async loginAdmin(credentials: IAuthCredentials): Promise<void> {
    let response;
    try {
      response = await ApiService.api.post('/auth/login', credentials);
    } catch (e) {
      throw new Error(
        `We couldn't find an account matching the email and password you entered. 
        Please check your email and password and try again.`
      );
    }
    const tokens: IAuthTokens = response.data;
    if (tokens.role !== 'admin') {
      throw new Error('You are not authorized to access this portal. Please contact admin');
    }
    AuthService.setAuthTokens(tokens);
    setTimeout(() => {
      AuthService.refreshTokens();
    }, response.data.expiresIn * 1000);
  }

  async uploadOfferCoverImage(formData: any, id: string): Promise<any> {
    const response = await ApiService.api.patch(`/admin/offer/${id}`, formData);

    const offerImages = response.data.data;
    return offerImages;
  }

  async updateCorporate(formData: any, id: string): Promise<any> {
    const response = await ApiService.api.patch(`/admin/corporate/${id}`, formData);

    const offerImages = response.data.data;
    return offerImages;
  }

  async updateBrand(formData: any, id: string): Promise<any> {
    const response = await ApiService.api.patch(`/admin/brand/${id}`, formData);

    const updatedBrands = response.data.data;
    return updatedBrands;
  }

  /**
   * Update global setting
   *
   * @param     formData    - Form data containing updated parameters
   * @param     id          - Global setting ID
   * @returns   Updated global setting
   */
  async updateGlobalSetting(payload: IGlobalSettingUpdate, id: string): Promise<IGlobalSettings> {
    const { data } = await ApiService.api.patch(`/admin/global-settings/${id}`, payload);
    return data;
  }

  // Customizable Email Services
  async fetchCustomEmail(id: string): Promise<IEmailCustomResponse> {
    const response = await ApiService.api.get(`/admin/notification/email/custom?offerId=${id}`);
    return response.data;
  }

  async addCustomEmail(payload: IEmailCustomResponse): Promise<IEmailCustomResponse> {
    const response: IEmailCustomResponse = await ApiService.api.post(
      `/admin/notification/email/custom`,
      payload
    );
    return response;
  }

  async updateCustomEmail(id: string, payload: IEmailCustomResponse): Promise<IEmailCustomResponse> {
    const response: IEmailCustomResponse = await ApiService.api.patch(
      `/admin/notification/email/custom/${id}`,
      payload
    );
    return response;
  }

  // Customizable SMS Services
  async fetchCustomSms(id: string): Promise<ISmsCustomResponse> {
    const response = await ApiService.api.get(`/admin/notification/sms/custom?offerId=${id}`);
    return response.data;
  }

  async addCustomSms(payload: ISmsCustomResponse): Promise<ISmsCustomResponse> {
    const response: ISmsCustomResponse = await ApiService.api.post(`/admin/notification/sms/custom`, payload);
    return response;
  }

  async updateCustomSms(id: string, payload: ISmsCustomResponse): Promise<ISmsCustomResponse> {
    const response: ISmsCustomResponse = await ApiService.api.patch(
      `/admin/notification/sms/custom/${id}`,
      payload
    );
    return response;
  }

  async sendTestEmail(payload: ITestEmailPayload): Promise<void> {
    return await ApiService.api.post(`/admin/notification/email/test`, payload);
  }

  async sendTestSms(payload: ITestSmsPayload): Promise<any> {
    return await ApiService.api.post(`/admin/notification/sms/test`, payload);
  }

  async sync(): Promise<void> {
    return await ApiService.api.get('sync/offers');
  }

  /**
   * Get offer redemptions
   *
   * @returns csv file with charges
   */
  async fetchOfferRedemptionsTransactions(payload: IOfferRedeemedRequest) {
    const response = await ApiService.api.get('/admin/report/offer-redemption', {
      params: payload,
      responseType: 'blob'
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'offer_redemptions.csv');
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /**
   * Upload new layout (background, overlay and banner) images
   */
  async uploadLayoutSettingsImages(formData: FormData): Promise<void> {
    await ApiService.api.patch('/admin/layout-settings/images', formData);
  }
}

const singleton = new AdminService();
export default singleton;
