import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const colors: Record<string, string> = {
  error: '#B20610',
  errorLight: '#EB493C',
  errorBackground: '#FFEAE9',
  primary: '#006ED2',
  primaryDark: '#0044A0',
  primaryLight: '#76B9F5',
  secondary: '#007043',
  secondaryDark: '#007043',
  secondaryLight: '#1ABB7A',
  success: '#287D3C',
  warning: '#EE651F',
  disabled: '#B6B6B6'
};

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        error: colors.error,
        primary: colors.primary,
        secondary: colors.secondary,
        success: colors.success,
        warning: colors.warning
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200
    }
  }
});
