// Utilities
import { ApiService } from '@/services';

// Types
import { IPaginationResult } from '@/types/base.types';
import {
  IGetTransactionRequest,
  INumberOfTransaction,
  ITransactionResponse
} from '@/types/transaction.types';

class TransactionService {
  // Get monthly and annual number of transactions
  async getNumberOfTransactions(): Promise<INumberOfTransaction> {
    const { data } = await ApiService.api.get('members/transaction-stats');
    return data;
  }

  /**
   * Get user transactions
   *
   * @param     request   - Call parameters
   * @returns   Transactions
   */
  async getTransactions(request: IGetTransactionRequest): Promise<IPaginationResult<ITransactionResponse>> {
    const { data } = await ApiService.api.get('/transaction', {
      params: {
        limit: request.paginationParams.itemsPerPage,
        page: request.paginationParams.page,
        startDate: request.period?.startDate,
        endDate: request.period?.endDate,
        brandId: request.brandId,
        type: request.type,
        sort: request.sort
      }
    });
    return data;
  }
}

const singleton = new TransactionService();
export default singleton;
