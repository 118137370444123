
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules';
import { IAuthUser } from '@/types/user.types';

@Component({})
export default class UserAvatar extends Vue {
  @Prop({ default: 76 })
  size!: number;
  @Prop({ default: '#d3e9fc' })
  color!: string;
  @Prop({ default: '#006ed2' })
  textColor!: string;

  /** Authenticated user */
  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get userInitials(): string {
    return this.user?.name
      ? this.user?.name
          .split(' ')
          .slice(0, 2)
          .map((w) => w[0])
          .join('')
      : '';
  }
}
