import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// Utilities
import config from '@/config';
import store from '@/store';

// Types
import { IAuthUser } from '@/types/user.types';
import { ICauseSettings } from '@/types/cause.types';

const moduleName = 'user';
// @ts-ignore
if (!config.production && store.state[moduleName]) {
  store.unregisterModule(moduleName);
}

@Module({ dynamic: true, name: moduleName, namespaced: true, store })
class UserState extends VuexModule {
  /** User profile */
  user: IAuthUser | null = null;

  /* Selected Program */
  cashbackOnly = false;
  role = '';

  /** Authenticated user */
  get authenticatedUser(): IAuthUser | null {
    return this.user;
  }

  /** Whether user is authenticated */
  get authenticated(): boolean {
    return Boolean(this.user);
  }

  /** User role */
  get userRole(): string {
    return this.user!.role;
  }

  /** User Cause */
  get userCause(): ICauseSettings | undefined {
    return this.user!.cause;
  }

  /** Whether user is verified (gates progress) */
  get verified(): boolean {
    return Boolean(this.user?.verifiedAt);
  }

  get hasPaymentMethod(): boolean {
    const paymentMethods = this.user?.paymentMethods.filter(
      (p) => p.oliveCardId != undefined && p.oliveCardId != null
    );
    return Boolean(paymentMethods?.length && paymentMethods?.length > 0 && this.user?.oliveMemberId);
  }

  /**
   * Initialize state
   */
  @Mutation
  initialize(): void {
    this.user = null;
  }

  /**
   * Set the user profile
   *
   * @param user - Authenticated user profile
   */
  @Mutation
  setUser(user: IAuthUser): void {
    this.user = user;
  }

  /**
   * Set program selected
   *
   * @param cashbackOnly
   */
  @Mutation
  setSelectedProgram(cashbackOnly: boolean): void {
    this.cashbackOnly = cashbackOnly;
  }

  /**
   * Clear the user profile
   */
  @Mutation
  removeUser(): void {
    this.user = null;
  }
}

export { UserState };
export default getModule(UserState);
