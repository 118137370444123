// Utilities
import { UserModule, AuthModule, FeatureModule, RoundUpModule } from '@/store/modules';
import ApiService, { createApiInstance } from './api.service';

// Types
import {
  IAuthCredentials,
  IAuthTokens,
  IForgotPassword,
  IForgotPasswordRequestReset
} from '@/types/auth.types';
import { FeatureService, RoundUpService, UserService } from '@/services';
import { IAuthUser } from '@/types/user.types';

const AUTH_TOKEN_KEY = 'authToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const USER_ID_KEY = 'userId';
const USER_ROLE = 'userRole';

class AuthService {
  // /** Authentication JWT token */
  // authToken: string | null = null;
  // /** Authentication refresh token */
  // refreshToken: string | null = null;
  /** Ongoing refresh token call (to prevent generating multiple refresh token calls) */
  refreshCall: Promise<void> | null = null;
  // /** Authenticated user ID */
  // userId: string | null = null;
  fetchUser: IAuthUser | null = null;

  /**
   * Determine whether user is authenticated
   *
   * @returns Whether user is authenticated
   */
  async hasAuthTokens(): Promise<boolean> {
    // Load authentication if not already loaded
    await this.loadAuth();

    return Boolean(AuthModule.tokens);
  }

  /**
   * Load stored authentication tokens
   */
  async loadAuth() {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY) ?? null;
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? null;
    const userId = localStorage.getItem(USER_ID_KEY) ?? null;
    const userRole = localStorage.getItem(USER_ROLE) ?? null;

    // If tokens exist in local storage add to vuex
    if (authToken && refreshToken && userId && userRole) {
      ApiService.setAuthToken(authToken);
      const currentTokens = AuthModule.tokens;
      // Check if current tokens are different from token in local storage
      if (
        authToken !== currentTokens?.token ||
        refreshToken !== currentTokens?.refreshToken ||
        userId !== currentTokens?.userId ||
        userRole !== currentTokens?.role
      ) {
        // If does override tokens in vuex
        AuthModule.setTokens({
          token: authToken,
          refreshToken,
          role: userRole,
          userId
        });

        // Get user new info
        this.fetchUser = await UserService.fetchUser();
        UserModule.setUser(this.fetchUser);
        return this.fetchUser;
      }
    } else {
      this.removeAuthTokens();
    }
  }

  /**
   * Log user in via credentials
   *
   * @param credentials - User credentials
   */
  async login(credentials: IAuthCredentials): Promise<void> {
    const response = await ApiService.api.post('/auth/login', credentials);
    const tokens: IAuthTokens = response.data;
    this.setAuthTokens(tokens);
    setTimeout(() => {
      this.refreshTokens();
    }, response.data.expiresIn * 1000);
    await FeatureService.getFeatureStatuses();
    RoundUpService.getRule();
    RoundUpService.getRoundUpStats();
  }

  /**
   * Logout authenticated user and clean up state
   */
  logout(): void {
    // Clean up authentication tokens
    this.removeAuthTokens();

    // Clean up Redux state
    UserModule.removeUser();
    FeatureModule.initialize();
    RoundUpModule.initialize();

    // TODO: Revoke current refresh token
  }

  /**
   * Refresh authentication tokens
   *
   * TODO: Investigate refreshing every so often
   * @returns Reference to ongoing refresh tokens API call
   */
  async refreshTokens(): Promise<void> {
    // Ignoring previous refresh call would invalidate that access token when the second request
    //   went through, causing another authentication error (voiding benefit entirely)!
    if (this.refreshCall) return this.refreshCall;

    // Avoid the ApiService axios instance to ensure that interceptors are not fired,
    // which could trigger an infinite loop IF the API was ever updated to use a
    // "Not Authenticated" status for invalid refresh tokens (future proof).
    this.refreshCall = createApiInstance()
      .post('/auth/refresh-token', {
        refreshToken: AuthModule.tokens?.refreshToken,
        userId: AuthModule.tokens?.userId
      })
      .then((response) => {
        this.setAuthTokens(response.data);
        setTimeout(() => {
          this.refreshTokens();
        }, response.data.expiresIn * 1000);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        this.refreshCall = null;
      });

    return this.refreshCall;
  }

  /**
   * Remove authentication tokens
   *
   * NOTE: Tokens are stored in memory and local storage
   */
  removeAuthTokens(): void {
    AuthModule.removeTokens();

    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(USER_ROLE);

    // Clear Axios authorization header
    ApiService.removeAuthToken();
  }

  /**
   * Store authentication tokens
   *
   * NOTE: Tokens are stored in memory and local storage
   *
   * @param tokens - Authentication tokens
   */
  setAuthTokens(tokens: IAuthTokens): void {
    const { refreshToken, role, token, userId } = tokens;

    AuthModule.setTokens({
      ...tokens
    });

    localStorage.setItem(AUTH_TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(USER_ID_KEY, userId);
    localStorage.setItem(USER_ROLE, role);

    // Set Axios authorization header
    ApiService.setAuthToken(token);
  }

  /**
   *
   * @param email
   *
   */
  async forgot(email: IForgotPassword): Promise<void> {
    return await ApiService.api.post('/forgot-password/request', email);
  }

  /**
   * Check user code
   *
   * @param code Unique user code
   * @returns boolean Return true if user code is valid false if not
   */
  async checkCode(code: IForgotPasswordRequestReset): Promise<boolean> {
    const isValid = await ApiService.api.post('/forgot-password/verify', code).then((result) => {
      return result.data;
    });

    return isValid;
  }

  /**
   * Reset user forgot password
   *
   * @param code  Unique user code
   * @param password User password
   * @returns
   */
  async resetPassword(code: string, password: string): Promise<void> {
    return await ApiService.api.post('/forgot-password/reset', {
      code,
      password
    });
  }

  /**
   * Resend email verification -- NOT used during onboarding
   *
   * @param user - User information
   */
  async resendEmailVerify(email?: string, code?: string): Promise<void> {
    if (email === undefined && code === undefined) return;
    await ApiService.api.post('/user/resend-verify-email', { email, code });
  }

  /** Send an email with a short code so that the user can verify their
   * account - used during onboarding only!
   */
  async sendVerifyAccountEmail(): Promise<void> {
    return ApiService.api.post('/user/verification-email');
  }

  /**
   * Verify newly registered user account
   *
   * @param code - Verification code (from email)
   */
  async verifyUser(code: string, email?: string): Promise<void> {
    const response = await ApiService.api.post('/user/verify', { code, email });
    const tokens: IAuthTokens = response.data;

    this.setAuthTokens(tokens);
  }

  /**
   * Update user email and resend verification email
   *
   * @param email - New user email
   */
  async updateAndResend(email: string): Promise<void> {
    const response = await ApiService.api.post('/user/edit-email', {
      email
    });
    const tokens: IAuthTokens = response.data;

    this.setAuthTokens(tokens);
  }
}

const singleton = new AuthService();
export default singleton;
