
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  @Prop({ default: null })
  text!: string | null;

  @Prop({ default: null })
  textClass!: string | null;

  @Prop({ default: 'primary' })
  subtitleTextClass!: string | null;

  // Needed to eliminate paragraph's bottom margin of 16 -- otherwise it takes
  // up vertical space even when there is no subtitle
  @Prop({ default: null })
  subtitleClass!: string | null;

  @Prop({ default: 'h4' })
  headerType!: string | null;

  @Prop({ default: 'center' })
  align!: string | null;
}
