
import { Component, Vue } from 'vue-property-decorator';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Utilities
import { AuthModule, FeatureModule, UserModule } from '@/store/modules';

// Types
import { ILink } from '@/types/global.types';
import { getLayoutData } from '@/utils/helperFunctions';

@Component
export default class Footer extends Vue {
  footerText = '';
  currentTenant = tenants[ActiveTenant];

  get authenticated(): boolean {
    return AuthModule.isAuthenticated;
  }
  get setupCompleted(): boolean {
    return UserModule.verified && UserModule.hasPaymentMethod;
  }
  get isLogoutRoute(): boolean {
    return this.$route.name === 'logout';
  }

  get socialLinks(): { icon: string; path: string }[] {
    const socialLinksData = getLayoutData();
    const links: { icon: string; path: string }[] = [];

    for (let i = 1; i <= 4; i++) {
      const iconKey = `icon${i}`;
      const linkKey = `link${i}`;

      if (socialLinksData[linkKey] && socialLinksData[iconKey])
        links.push({
          icon: socialLinksData[iconKey],
          path: socialLinksData[linkKey]
        });
    }
    return links;
  }

  get appLinks(): ILink[] {
    const links = [{ order: 4, path: '/settings/profile', text: 'Settings' }];
    if (FeatureModule.isOffersEnabled) {
      links.push(
        ...[
          { order: 1, path: '/offers', text: 'Offers' },
          { order: 2, path: '/history', text: 'Transaction History' },
          { order: 3, path: '/dashboard', text: 'Impact Dashboard' }
        ]
      );
    }
    links.sort((link1, link2) => link1.order - link2.order);
    return links;
  }

  get externalLinks(): ILink[] {
    return [
      {
        order: 1,
        path: this.currentTenant.links.termsConditions,
        text: 'Terms and conditions'
      },
      {
        order: 2,
        path: this.currentTenant.links.privacyPolicy,
        text: 'Privacy Policy'
      },
      {
        order: 3,
        path: 'mailto:support@hopefactory.ca',
        text: 'Get Help'
      }
    ];
  }

  mounted(): void {
    const footerDetails = getLayoutData();
    this.footerText = footerDetails.footerText;
  }
}
