import { RouteConfig } from 'vue-router';

// Anonymous Components Routes
import { AdminLogin } from '@/views/admin';

// Auth Components Routes
import { EmailVerification, ForgotPassword, Login, Logout, Register, ResetPassword } from '@/views/auth';

import { Offers } from '@/views/offers';
import { getHomeRoute } from './routes';
import { FeatureModule } from '@/store/modules';
import { FeatureService } from '@/services';

const anonymousRoutes: RouteConfig[] = [
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresNoAuth: true }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requiresNoAuth: true }
  },
  {
    path: '/',
    name: 'home',
    redirect: () => getHomeRoute()
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/login'
  },
  // `email-verification` is also used in the Forgot Password flow!
  {
    path: '/email-verification',
    name: 'emailVerification',
    component: EmailVerification
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:code',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/offers',
    name: 'offers',
    component: Offers,
    meta: { securedLayout: true },
    beforeEnter: async (to, from, next) => {
      const response = await FeatureService.getFeatureStatuses();
      const isOffersEnabled = response.isOffersEnabled;
      if (isOffersEnabled) {
        // Proceed to the route if the feature is enabled
        next();
      } else {
        // Redirect or handle unauthorized access (for example, redirect to another route)
        next('/dashboard'); // Redirect to the home route or another route of your choice
      }
    }
  }
];

export default anonymousRoutes;
