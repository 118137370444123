
import { Component, Vue } from 'vue-property-decorator';
import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import { notification } from '@/plugins/snackbar';

@Component
export default class TheAppNotification extends Vue {
  icons = {
    error: mdiAlertCircle,
    success: mdiCheckCircle
  };

  get screenWidth(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return `${this.$vuetify.breakpoint.width}px`;
      case 'sm':
        return `${this.$vuetify.breakpoint.width}px`;
      case 'md':
        return `${this.$vuetify.breakpoint.width}px`;
      default:
        return 'auto';
    }
  }

  get snackbar() {
    const {
      text = '',
      type = 'success',
      visible,
      background,
      centered
    } = notification;

    let icon = this.icons.success; // TODO
    switch (type) {
      case 'success':
        icon = this.icons.success;
        break;
      case 'error':
        icon = this.icons.error;
        break;
      case 'warning':
        icon = this.icons.success; //TODO
        break;
      case 'info':
        icon = this.icons.success; //TODO
        break;
      default:
        icon = this.icons.success;
        break;
    }

    return {
      icon,
      text,
      type,
      visible,
      background,
      centered
    };
  }
}
