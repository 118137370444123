import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// Utilities
import store from '@/store';

// Types
import { IImpactStats } from '@/types/stats.types';

@Module({ dynamic: true, name: 'stats', namespaced: true, store })
class StatsState extends VuexModule {
  impactStats: IImpactStats = {};

  /** Retrieve impact stats */
  get getImpactStats(): IImpactStats {
    return this.impactStats;
  }

  /** Initialize round up to default state */
  @Action
  initialize(): void {
    this.setImpactStats({});
  }

  @Mutation
  setImpactStats(impactStats: IImpactStats): void {
    this.impactStats = impactStats;
  }
}

export default getModule(StatsState);
