
import { Component, Vue } from 'vue-property-decorator';
import { mdiChevronDown, mdiChevronRight, mdiClose } from '@mdi/js';

// Components
import UserAvatar from '@/components/UserAvatar.vue';
import OnboardingHeader from '@/components/OnboardingHeader.vue';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Utilities
import { AuthModule, FeatureModule, StatsModule, UserModule } from '@/store/modules';
import { StatsService } from '@/services';
import isMobile from '@/utils/mobile-detect';

// Types
import { ILink } from '@/types/global.types';
import { IImpactStats } from '@/types/stats.types';
import { IAuthUser } from '@/types/user.types';

@Component({
  components: {
    UserAvatar,
    OnboardingHeader
  }
})
export default class AppHeader extends Vue {
  currency: any;
  currentTenant = tenants[ActiveTenant];
  avatarColor = this.currentTenant.text.avatarColor;
  avatarBackgroundColor = this.currentTenant.userAvatar.backgroundColor;
  avatarTextColor = this.currentTenant.userAvatar.textColor;
  mobileAvatarBackgroundColor = this.currentTenant.userAvatar.mobileBackgroundColor;
  mobileAvatarTextColor = this.currentTenant.userAvatar.mobileTextColor;
  dark = this.currentTenant.colorScheme.dark;
  headerBackground = this.currentTenant.menuHeaderBackground;
  icons = { closeMenu: mdiClose, openMenu: mdiChevronDown, rightChevron: mdiChevronRight };
  textColor = this.currentTenant.colorScheme.dark ? '#000000' : '#ffffff';
  upperHeaderColor = this.currentTenant.appHeader.upperHeaderColor;
  upperHeaderImage = this.currentTenant.appHeader.upperHeaderImage;
  mobileHeaderImage = this.currentTenant.appHeader.mobileHeaderImage;
  mobileHeaderSize = this.currentTenant.appHeader.mobileHeaderSize;
  headerButtonColor = this.currentTenant.headerButtonColor;

  authenticated = AuthModule.isAuthenticated;
  impactStatsDisplay: { icon: string; key: keyof IImpactStats; title: string; tooltipText: string }[] = [
    {
      icon: this.spendIcon,
      key: 'programSpent',
      title: 'Program Spend Balance',
      tooltipText: 'Program Spend - total lifetime spend in program since enrolling'
    },
    {
      icon: this.impactIcon,
      key: 'socialImpact',
      title: 'Total Social Impact',
      tooltipText:
        'Social Impact - total positive social impact generated through the program since enrolling'
    },
    {
      icon: this.roundUpIcon,
      key: 'roundUps',
      title: 'Round UP Balance',
      tooltipText: 'Round UPs - current Round UP balance for the month'
    },
    {
      icon: this.cashForwardIcon,
      key: 'cashForward',
      title: 'Cash Forward Balance',
      tooltipText: 'Cash Forward - total cash forward balance'
    },
    {
      icon: this.pointsIcon,
      key: 'points',
      title: 'Points Balance',
      tooltipText: 'Points - current (unredeemed) points balance'
    }
  ];
  impactStats = StatsModule.getImpactStats;
  isMobile = isMobile();
  menuDisplayMobile = false;
  userRole = UserModule.userRole;
  features = FeatureModule.getFeatureStatuses;
  isMultipleCauseEnabled = this.features.isMultipleCauseEnabled;
  isOffersEnabled = this.features.isOffersEnabled;
  isRoundUpEnabled = this.features.isRoundUpEnabled;

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get currentCause(): any {
    return this.user?.cause;
  }

  // Lower bar header - cause category related
  get lowerBarHeader(): any {
    if (this.isMultipleCauseEnabled && this.currentCause) {
      return {
        mainColor: `#${this.currentCause.category.primaryColor}`,
        reversedTextColor: `#${this.currentCause.category.secondaryColor}`,
        lowerHeaderImage: this.currentCause.category.categoryImageUrl,
        lowerHeaderImageSize: this.currentCause.category.categoryImageHeight,
        lowerHeaderImageWidth: this.currentCause.category.categoryImageWidth
      };
    } else {
      return {
        mainColor: this.currentTenant.colorScheme.main,
        lowerHeaderImage: this.currentTenant.appHeader.lowerHeaderImage,
        reversedTextColor: this.currentTenant.colorScheme.dark ? '#ffffff' : '#000000',
        lowerHeaderImageSize: this.currentTenant.appHeader.lowerHeaderHeight,
        lowerHeaderImageWidth: '200px'
      };
    }
  }

  get adminLinks(): ILink[] {
    const links = [
      { order: 3, path: '/admin/corporate', text: 'Boosters' },
      { order: 4, path: '/admin/global-settings', text: 'Global Settings' },
      { order: 5, path: '/admin/settings', text: 'Layout Settings' },
      { order: 6, path: '/admin/reports', text: 'Reports' }
    ];
    if (this.isOffersEnabled) {
      links.push(
        ...[
          { order: 1, path: '/admin/offer', text: 'Offer' },
          { order: 2, path: '/admin/brand', text: 'Offer Order' }
        ]
      );
    }
    if (this.isMultipleCauseEnabled) {
      links.push(...[{ order: 7, path: '/admin/causes', text: 'Causes' }]);
    }

    links.sort((link1, link2) => link1.order - link2.order);
    return links;
  }

  get authenticatedLinks(): ILink[] {
    const links: ILink[] = [];
    if (this.isOffersEnabled) {
      links.push(
        ...[
          { order: 1, path: '/offers', text: 'Offers + Boosts' },
          { order: 3, path: '/history', text: 'Transaction History' }
        ]
      );
    }

    if (this.isOffersEnabled && this.isRoundUpEnabled) {
      links.push({ order: 2, path: '/dashboard?scrollToRoundUp=true', text: 'Round UP' });
    } else if (this.isRoundUpEnabled) {
      links.push({ order: 1, path: '/settings/round-up-program', text: 'Manage Round UP' });

      if (this.isMultipleCauseEnabled) {
        links.push({
          order: 2,
          path: this.currentCause ? '/causes' : '/settings/change-cause',
          text: `About ${this.currentCause.name}`
        });
      }
    }
    links.sort((link1, link2) => link1.order - link2.order);
    return links;
  }

  get drawerLinks(): ILink[] {
    return this.authenticated ? [...this.authenticatedLinks, ...this.dropdownLinks] : [];
  }

  get links(): ILink[] {
    if (!this.authenticated) return [];

    if (this.userRole === 'admin') {
      return this.adminLinks;
    }

    return this.authenticatedLinks;
  }

  get cashForwardIcon(): string {
    return this.dark
      ? require('@/assets/icons/cashforward-dark.svg')
      : require('@/assets/icons/cashforward-light.svg');
  }

  get dropdownLinks(): ILink[] {
    return [
      {
        order: 1,
        path: isMobile() === true ? '/settings' : '/settings/profile',
        text: 'Settings'
      },
      { order: 2, path: '/logout', text: 'Logout' }
    ];
  }

  get impactIcon(): string {
    return this.dark ? require('@/assets/icons/impact-dark.svg') : require('@/assets/icons/impact-light.svg');
  }

  get pointsIcon(): string {
    return this.dark ? require('@/assets/icons/points-dark.svg') : require('@/assets/icons/points-light.svg');
  }

  get roundUpIcon(): string {
    return this.dark
      ? require('@/assets/icons/roundup-dark.svg')
      : require('@/assets/icons/roundup-light.svg');
  }

  get spendIcon(): string {
    return this.dark ? require('@/assets/icons/spend-dark.svg') : require('@/assets/icons/spend-light.svg');
  }

  created(): void {
    if (!Object.keys(this.impactStats).length) this.getImpactStats();
  }

  async getImpactStats(): Promise<void> {
    this.impactStats = await StatsService.getImpactStats();
  }
}
