// Utilities
import { ApiService } from '@/services';
import { RoundUpModule } from '@/store/modules';

// Types
import { IPaginationParamsVDataTable, IPaginationResult, ITimePeriod } from '@/types/base.types';
import {
  IPauseRoundUp,
  IRoundUpStats,
  IRoundUpRuleResponse,
  ICharge,
  IRoundUpRuleGlobalSettings,
  IRoundUpRuleUpdate
} from '@/types/round-up.types';

class RoundUpService {
  /**
   * Create Round Up and Add it to all user's card
   *
   * @returns RoundUp Rule
   */
  async createRoundUpRule(payload: IRoundUpRuleUpdate): Promise<IRoundUpRuleResponse> {
    const { data } = await ApiService.api.post('/round-up/rules', payload);
    RoundUpModule.setRoundUp(data);
    return data;
  }

  /**
   * Get successful charges for user
   *
   * @param   paginationParams    - Params for paginating pages
   * @param   period              - Requested time period
   * @param   sort                - (Optional) Sort props
   * @returns Charges
   */
  async getCharges(
    paginationParams: IPaginationParamsVDataTable,
    period: ITimePeriod,
    sort?: string
  ): Promise<IPaginationResult<ICharge>> {
    const { data } = await ApiService.api.get('/charge', {
      params: {
        limit: paginationParams.itemsPerPage,
        page: paginationParams.page,
        startDate: period.startDate,
        endDate: period.endDate,
        sort
      }
    });
    return data;
  }

  /**
   * Get Ecosystem minimum amount
   *
   * @returns minimum amount
   */
  async getRoundUpGlobalSettings(): Promise<IRoundUpRuleGlobalSettings> {
    const { data } = await ApiService.api.get('/round-up/rules/global-settings');
    RoundUpModule.setGlobalSettings(data);
    return data;
  }

  async getRoundUpStats(): Promise<IRoundUpStats> {
    const { data } = await ApiService.api.get('/round-up/rules/stats');
    RoundUpModule.setRoundUpStats(data);
    return data;
  }

  async getRule(): Promise<IRoundUpRuleResponse | null> {
    try {
      const { data } = await ApiService.api.get('/round-up/rules');
      RoundUpModule.setRoundUp(data);
      return data;
    } catch (err) {
      // Suppress error
      return null;
    }
  }

  /**
   * Pause Round Up
   *
   * @returns RoundUp Rule
   */
  async pauseRoundUpRule(payload: IPauseRoundUp): Promise<IRoundUpRuleResponse> {
    const response = await ApiService.api.patch('/round-up/rules/pause', payload);
    return response.data;
  }

  /**
   * Unpause Round Up
   *
   * @returns RoundUp Rule
   */
  async resumeRoundUpRule(): Promise<IRoundUpRuleResponse> {
    const { data } = await ApiService.api.patch('/round-up/rules/unpause');
    RoundUpModule.setRoundUp(data);
    return data;
  }

  /**
   * Create Round Up and Add it to all user's card
   *
   * @returns RoundUp Rule
   */
  async updateRoundUpRule(payload: IRoundUpRuleUpdate): Promise<IRoundUpRuleResponse> {
    const { data } = await ApiService.api.patch('/round-up/rules', payload);
    RoundUpModule.setRoundUp(data);
    return data;
  }
}

const singleton = new RoundUpService();
export default singleton;
