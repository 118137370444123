// Utilities
import config from '@/config';
import router from '@/router';
import { AuthService } from '@/services';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import HttpStatus from 'http-status-codes';
import qs from 'qs';

/**
 * Create a common Axios instance using a common config, which can be used
 *   for creating custom Axios instances without interceptors, etc.
 *
 * NOTE: Use with caution, preferring the preconfigured 'ApiService.api' instance instead!
 *
 * @param   overrides - Optional Axios instance config overrides
 * @returns Custom Axios instance from common config
 */
const createApiInstance = (
  overrides: AxiosRequestConfig = {}
): AxiosInstance => {
  return axios.create({
    baseURL: `${config.api.url}/api`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'App-Version': config.app.version
    },
    timeout: 100000,

    // Parameters are serialized to support arrays (bracket/repeated notation).
    // NOTE: This doesn't work with arrays of objects, which require "index" mode.
    //         However, since this is a slightly longer query is should be
    //         only used where appropriate (by overriding in request).
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'brackets' }),
    ...overrides
  });
};

class ApiService {
  /** API axios instance */
  api: AxiosInstance;

  constructor() {
    this.api = createApiInstance();

    // Intercept and handle authentication errors
    this.api.interceptors.response.use(
      (response) => response,
      (error) => this.interceptErrors(error)
    );
  }

  /**
   * Intercept Axios errors and check for authentication problems
   *
   * @param   error - Axios response error
   * @returns Axios response chain
   */
  async interceptErrors(error: any): Promise<any> {
    const status = error.response?.status ?? null;

    /* Only proceed to refresh the auth token if user had a previous
    authentication token and the error was actually an auth error.
    */
    if (
      status !== HttpStatus.UNAUTHORIZED ||
      !(await AuthService.hasAuthTokens())
    ) {
      return Promise.reject(error);
    }

    try {
      await AuthService.refreshTokens();
    } catch (refreshTokensErr) {
      /* We needed to refresh auth tokens, but we failed.
      Regardless of the reason, we need to log the user out and ask him/her
      to log in again.
      */

      this.handleRefreshAuthError();

      return Promise.reject(refreshTokensErr);
    }

    try {
      // Retry original request (after refreshing auth tokens) by copying config
      const retryConfig = {
        ...error.config,
        headers: {
          ...error.config.headers,
          ...this.api.defaults.headers
        }
      };

      // Cannot use the api singleton because it has an error interceptor
      // that can lead to recursive calls of this interceptor - if the api
      // keeps returning status 401
      const retryApi = createApiInstance();
      return await retryApi(retryConfig);
    } catch (innerError: any) {
      // Only logout user if retry error was actually related to auth (and not just a failed request)
      const innerStatus = innerError.response?.status ?? null;
      if (innerStatus === HttpStatus.UNAUTHORIZED) {
        this.handleRefreshAuthError();

        return Promise.reject('Authentication has timed out');
      }

      return Promise.reject(innerError);
    }
  }

  /** Clean up and redirect to login after a refresh authentication error */
  handleRefreshAuthError(): void {
    AuthService.logout();

    const { fullPath } = router.currentRoute;
    const redirectUrl = fullPath === '/' ? 'offers' : fullPath;
    router.replace({
      path: '/login',
      query: { redirectUrl }
    });
  }

  /**
   * Remove Axios authentication token
   */
  removeAuthToken(): void {
    this.api.defaults.headers['Authorization'] = null;
  }

  /**
   * Set Axios authentication token
   *
   * @param token - Authentication header token
   */
  setAuthToken(token: string): void {
    this.api.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
}

const singleton = new ApiService();
export default singleton;

export { createApiInstance };
