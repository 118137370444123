import Vue, { VueConstructor } from 'vue';

interface INotification {
  background?: string;
  text?: string;
  timeout?: number;
  type?: string;
  visible?: boolean;
  centered?: boolean;
}

const notification = Vue.observable({
  background: '',
  text: '',
  visible: false,
  centered: false
}) as INotification;

const showSnackbar = (
  text: string,
  background?: string,
  timeout?: number,
  type?: string,
  centered?: boolean
): void => {
  notification.visible = false; // Important to detect change on notification

  setTimeout(() => {
    notification.background = background;
    notification.text = text;
    notification.timeout = timeout;
    notification.type = type;
    notification.visible = true;
    notification.centered = centered;
  }, 100);
};

const notifyError = (text: string, centered?: boolean): void => {
  showSnackbar(text, '#FFEAE9', 2000, 'error', centered);
};
const notifyInfo = (text: string): void => {
  showSnackbar(text, '#E8F0E8', 2000, 'info'); // success background color
};
const notifyWarning = (text: string): void => {
  showSnackbar(text, '#E8F0E8', 2000, 'warning'); // success background color
};

const notifySuccess = (text: string, centered?: boolean): void => {
  showSnackbar(text, '#E8F0E8', 2000, 'success', centered);
};

const SnackbarPlugin = {
  install: (Vue: VueConstructor): void => {
    Vue.prototype.$notifySuccess = notifySuccess;
    Vue.prototype.$notifyError = notifyError;
    Vue.prototype.$notifyInfo = notifyInfo;
    Vue.prototype.$notifyWarning = notifyWarning;
  }
};

export default SnackbarPlugin;
export {
  INotification,
  notifySuccess,
  notifyError,
  notifyInfo,
  notifyWarning,
  notification
};
