
import { Component, Vue } from 'vue-property-decorator';
import { AuthModule, UserModule } from '@/store/modules';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { getLayoutData } from '@/utils/helperFunctions';

@Component
export default class Footer extends Vue {
  tenants = tenants;
  currentTenant = tenants[ActiveTenant];

  footerBackground = this.currentTenant.footerBackground;
  footerText = '';
  faqsWebsite = this.currentTenant.links.faqs;

  get authenticated(): boolean {
    return AuthModule.isAuthenticated;
  }
  get setupCompleted(): boolean {
    return UserModule.verified && UserModule.hasPaymentMethod;
  }
  get isLogoutRoute(): boolean {
    return this.$route.name === 'logout';
  }

  get socialLinks(): { icon: string; path: string }[] {
    const socialLinksData = getLayoutData();
    const links: { icon: string; path: string }[] = [];

    for (let i = 1; i <= 4; i++) {
      const iconKey = `icon${i}`;
      const linkKey = `link${i}`;

      if (socialLinksData[linkKey] && socialLinksData[iconKey])
        links.push({
          icon: socialLinksData[iconKey],
          path: socialLinksData[linkKey]
        });
    }
    return links;
  }

  get appLinks(): { path: string; text: string }[] {
    return [
      {
        path: this.currentTenant.links.termsConditions,
        text: 'Terms + Conditions'
      },
      { path: this.currentTenant.links.privacyPolicy, text: 'Privacy Policy' },
      { path: `mailto: ${this.currentTenant.email}`, text: 'Support' }
    ];
  }
  get externalLinks(): { text: string; path: string }[] {
    return [
      {
        text: 'Terms and conditions',
        path: this.currentTenant.links.termsConditions
      },
      {
        text: 'Privacy Policy',
        path: this.currentTenant.links.privacyPolicy
      },
      {
        text: 'Get Help',
        path: 'mailto: hello@leasidelocal.com'
      }
    ];
  }

  mounted(): void {
    const footerDetails = getLayoutData();
    this.footerText = footerDetails.footerText;
  }
}
