
import { Component, Vue } from 'vue-property-decorator';
import { version } from '../../../package.json';

import SecuredPageLayout from '@/components/SecuredPageLayout.vue';
import { ApiService } from '@/services';
import { FeatureModule } from '@/store/modules';

@Component({
  components: {
    SecuredPageLayout
  }
})
export default class SideMenu extends Vue {
  apiVersion = '';
  webVersion = '';
  links = [
    { path: '/settings/profile', text: 'Profile', disabled: false },
    { path: '/settings/change-password', text: 'Change Password' },
    { path: '/settings/enroll-card', text: 'Manage Cards' },
    { path: '/settings/notifications', text: 'Notifications' },
    { path: '/settings/referrals', text: 'Referral Program' }
  ];

  async created(): Promise<void> {
    let response = await ApiService.api.get('');
    this.apiVersion = response.data.version;
    this.webVersion = version;

    const { isRoundUpEnabled, isMultipleCauseEnabled } = FeatureModule.getFeatureStatuses;
    if (isMultipleCauseEnabled) {
      this.links.splice(4, 0, {
        path: '/settings/change-cause',
        text: 'Manage Charity'
      });
    }

    if (isRoundUpEnabled) {
      this.links.splice(5, 0, {
        path: '/settings/round-up-program',
        text: 'Round UP Program'
      });
    }
  }
}
