import { RouteConfig } from 'vue-router';

// Secured Components Routes
import { CreditCardSetup, CompletedSetup } from '@/views/accountSetup';
import { OfferDetailsPage } from '@/views/offers';
import { Dashboard } from '@/views/dashboard';
import { TransactionHistory } from '@/views/history';
import {
  DeactivateCards,
  AddCards,
  LinkedCards,
  ChangePassword,
  Notifications,
  ProfilePage,
  Settings,
  CloseAccount,
  ReferralProgram,
  RoundUpProgram,
  ChangeCause
} from '@/views/settings';
import {
  AdminOffers,
  AdminBrands,
  AdminCorporates,
  AdminReports,
  GlobalSettings,
  AdminSettings,
  AdminCauses
} from '@/views/admin';

// Auth Components Routes
import { AccountVerification, EditEmail } from '@/views/auth';

import { SetNotification } from '@/views/accountSetup';

import SideMenu from '@/components/settings/SideMenu.vue';
import { Causes } from '@/views/causes';

const securedRoutes: RouteConfig[] = [
  {
    path: '/card-setup',
    name: 'creditCardSetup',
    component: CreditCardSetup,
    meta: { requiresAuth: true, onboardingStep: 2 }
  },
  {
    path: '/completed-setup',
    name: 'completedSetup',
    component: CompletedSetup,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/offer-details',
    name: 'OffersDetails',
    component: OfferDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/causes',
    name: 'Causes',
    component: Causes,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/dashboard?scrollToRoundUp=true',
    name: 'RoundUpDashboard',
    component: Dashboard,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/history',
    name: 'History',
    component: TransactionHistory,
    meta: { requiresAuth: true, securedLayout: true }
  },

  {
    path: '/settings',
    // name: 'Settings',
    component: SideMenu,
    children: [
      {
        path: 'deactivate-cards',
        name: 'DeactivateCards',
        component: DeactivateCards,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: '',
        name: 'SettingsMobile',
        component: Settings,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ProfilePage,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'enroll-card',
        name: 'EnrollCard',
        component: LinkedCards,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'add-cards',
        name: 'AddCards',
        component: AddCards,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'referrals',
        name: 'Referrals',
        component: ReferralProgram,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'round-up-program',
        name: 'RoundUpProgram',
        component: RoundUpProgram,
        meta: { requiresAuth: true, securedLayout: true }
      },
      {
        path: 'change-cause',
        name: 'ChangeCause',
        component: ChangeCause,
        meta: { requiresAuth: true, securedLayout: true }
      }
    ]
  },
  {
    path: '/close-account',
    name: 'CloseAccount',
    component: CloseAccount,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/offer',
    name: 'AdminOffer',
    component: AdminOffers,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/brand',
    name: 'AdminBrand',
    component: AdminBrands,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/corporate',
    name: 'AdminCorporate',
    component: AdminCorporates,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/global-settings',
    name: 'GlobalSetting',
    component: GlobalSettings,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/reports',
    name: 'Reports',
    component: AdminReports,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/causes',
    component: AdminCauses,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/admin/settings',
    name: 'Settings',
    component: AdminSettings,
    meta: { requiresAuth: true, securedLayout: true }
  },
  {
    path: '/account-verification',
    name: 'accountVerification',
    component: AccountVerification,
    meta: { onboardingStep: 4 }
  },
  {
    path: '/edit-email',
    name: 'editEmail',
    component: EditEmail,
    meta: { requiresAuth: true }
  },
  {
    path: '/set-notifications',
    name: 'setNotification',
    component: SetNotification,
    meta: { requiresAuth: true, onboardingStep: '3' }
  }
];

export default securedRoutes;
