// Utilities
import { AuthService, ApiService } from '@/services';
import { UserModule } from '@/store/modules';

// Types
import { IAuthUser, IUserUpdateFields, IUserRegister } from '@/types/user.types';
import { IAuthTokens } from '@/types/auth.types';

class UserService {
  /**
   * Fetch authenticated user information
   *
   * @returns Authenticated user information
   */
  async fetchUser(): Promise<IAuthUser> {
    const response = await ApiService.api.get('/user/profile');
    const user = response.data;

    // TODO: Determine where we store in Redux

    return {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      postalCode: user.postalCode,
      mobilePhoneNumber: user.mobilePhoneNumber,
      id: user.id,
      verifiedAt: user.verifiedAt ?? null,
      paymentMethods: user.paymentMethods,
      referralCode: user.referralCode,
      oliveMemberId: user.oliveMemberId,
      cashbackProgram: user.cashbackProgram,
      roundingProgram: user.roundingProgram,
      notificationSettings: user.notificationSettings,
      role: user.role,
      cause: user.cause
    };
  }

  getAuthenticatedUser(): IAuthUser | null {
    return UserModule.authenticatedUser;
  }

  /**
   *  Update user profile (bio, social links, password)
   */
  async updateProfile(user: IUserUpdateFields): Promise<void> {
    return await ApiService.api.put('/user/profile', user);
  }

  /**
   * Update user avatar
   * @param payload Form with image file
   */
  async updateAvatar(payload: FormData, userId?: string): Promise<IAuthUser | undefined> {
    if (!userId) return;
    return (await ApiService.api.post('/user/update-profile-avatar/' + userId, payload)).data;
  }

  /**
   * Register a user
   *
   * @param user - User information
   */
  async register(user: IUserRegister): Promise<void> {
    const response = await ApiService.api.post('/user/register', user);
    const tokens: IAuthTokens = response.data;

    AuthService.setAuthTokens(tokens);
  }

  /**
   * Save Payment Method
   *
   * @param user - User information
   */
  async addPaymentMethod(paymentMethodData: any) {
    const response = await ApiService.api.post('/user/payment-method', paymentMethodData);
    return response.data;
  }
}

const singleton = new UserService();
export default singleton;
