// Utilities
import { ApiService } from '@/services';
import { StatsModule } from '@/store/modules';

// Types
import { IImpactStats } from '@/types/stats.types';

class StatsService {
  // Get impact stats and set it in store
  async getImpactStats(): Promise<IImpactStats> {
    const { data } = await ApiService.api.get('stats-summary/impact-stats');
    StatsModule.setImpactStats(data);
    return data;
  }
}

const singleton = new StatsService();
export default singleton;
