const Notifications = () => import('./Notifications.vue');
const Settings = () => import('./Settings.vue');
const ProfilePage = () => import('./ProfilePage.vue');
const ChangePassword = () => import('./ChangePassword.vue');
const LinkedCards = () => import('./LinkedCards.vue');
const AddCards = () => import('./AddCards.vue');
const DeactivateCards = () => import('./DeactivateCards.vue');
const CloseAccount = () => import('./CloseAccount.vue');
const ReferralProgram = () => import('./ReferralProgram.vue');
const RoundUpProgram = () => import('./RoundUpProgram.vue');
const AddRoundUpProgram = () => import('./AddRoundUpProgram.vue');
const ChangeCause = () => import('./ChangeCause.vue');

export {
  Notifications,
  Settings,
  ProfilePage,
  ChangePassword,
  LinkedCards,
  AddCards,
  DeactivateCards,
  CloseAccount,
  ReferralProgram,
  RoundUpProgram,
  AddRoundUpProgram,
  ChangeCause
};
