import VueRouter from 'vue-router';

// Utilities
import { AuthService, FeatureService, UserService } from '@/services';

import securedRoutes from './routes-secured';
import { getHomeRoute } from './routes';

const onboardingRoutes = securedRoutes.filter((r) => r.meta?.onboardingStep).map((r) => r.path);

const guardRouter = (router: VueRouter): void => {
  router.beforeEach(async (to, from, next) => {
    const isRoutedByApp = Boolean(from.name);

    const isAuthenticated = await AuthService.hasAuthTokens();
    const authUser = UserService.getAuthenticatedUser();
    const requiresAuth = to.matched.some((r) => r.meta && r.meta.requiresAuth);

    const isOnboarding = Boolean(authUser && !authUser.verifiedAt);
    const isOnboardingRoute = onboardingRoutes.includes(to.path);
    const hasSetNotifications = Boolean(authUser?.notificationSettings);

    if (isAuthenticated) await FeatureService.getFeatureStatuses();
    /*
    We currently allow users to register a card that already exists in Olive.
    If the card was registered under a different email address, we won't have
    the Olive id in the payment method record for the new email address.
    We fix these edge cases manually, so we need to let a user proceed in
    onboarding, even if there is no Olive card id i their payment method.

    TODO: Once Olive is able to tell us the id of the duplicate card, we will
    handle these cases automatically and the payment method will always have
    an Olive card id. Then, we can revert back to checking here for the Olive
    card id.
    */
    // const paymentMethods = authUser?.paymentMethods.filter(
    //   (p) => p.oliveCardId != undefined && p.oliveCardId != null
    // );

    const isPaymentSetupCompleted = Boolean(authUser?.paymentMethods?.length && authUser?.oliveMemberId);

    if (isOnboarding) {
      if (isOnboardingRoute) {
        let farthestAllowedOnboardingStep: number;

        if (hasSetNotifications) {
          farthestAllowedOnboardingStep = 4;
        } else if (isPaymentSetupCompleted) {
          farthestAllowedOnboardingStep = 3;
        } else {
          farthestAllowedOnboardingStep = 2;
        }

        // Check if user is accessing an allowed onboarding route
        if (to.meta && parseInt(to.meta.onboardingStep) <= farthestAllowedOnboardingStep) {
          return next();
        }

        /*
        Redirect to beginning of registration to avoid invalid route state
        and to guarantee we will not end in an infinite loop -- so, don't 
        redirect to another onboarding route.
        */
        return next('/register');
      } else {
        // Since user is abandoning onboarding, we need to make him/her unauthenticated

        /* An exception needs to be made for '/edit-email' route because it's
        used during onboarding, but also outside it too (in the Forgot Password
        flow).
          */
        const hybridRoute = '/edit-email';

        if (to.path !== hybridRoute) {
          AuthService.logout();
        }

        if (requiresAuth && to.path !== hybridRoute) {
          return next({ path: '/login', query: { redirectUrl: to.fullPath } });
        } else {
          return next();
        }
      }
    } else if (isOnboardingRoute) {
      // user is not onboarding so this route is not accessible
      return next(getHomeRoute());
    }
    // Protect routes that require authentication
    else if (requiresAuth) {
      if (!isAuthenticated) {
        return next({ path: '/login', query: { redirectUrl: to.fullPath } });
      }

      return next();
    } else if (to.matched.some((r) => r.meta && r.meta.requiresNoAuth)) {
      // Some routes cannot be accessed when authenticated (all matching routes must be checked)
      if (isAuthenticated) {
        // Cancel navigation if it occurred within the app (not a refresh or manual route load).
        if (isRoutedByApp) return next(false);

        // Otherwise, redirect to home (necessary to avoid invalid route state).
        return next(getHomeRoute());
      }
      return next();
    } else if (to.path === '/mailto') {
      window.location.replace('mailto:support@hopefactory.ca');
      return;
    }

    return next();
  });
};

export default guardRouter;
