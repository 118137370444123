// Utilities
import { ApiService } from '@/services';

// Types
import { IBrand } from '@/types/offer.types';

class BrandService {
  /** Get brands, in whose stores user had transactions */
  async getTransactionBrands(): Promise<IBrand[]> {
    const { data } = await ApiService.api.get('brand', { params: { transactionsOnly: true } });
    return data;
  }
}

const singleton = new BrandService();
export default singleton;
