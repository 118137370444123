import { VueConstructor } from 'vue/types/vue';
import dayjs from 'dayjs';

/**
 * Format a Date with DayJS
 *
 * @param   value  - Input date
 * @param   format - Date string format
 * @returns Formatted date string
 */
const formatDate = (value: Date | null, format: string): string => {
  if (!value) return '';
  return dayjs(value).format(format ?? 'MMMM DD, YYYY');
};

const formatPhoneNumber = (value: string | null): string => {
  if (!value) return '';
  const cleaned = ('' + value).replace(' ', '');
  const match = cleaned.match(/^(.{0,3})(.{0,3})(.{0,})$/);
  if (match) {
    return match[1] + (match[2] ? ' ' + match[2] + (match[3] ? ' ' + match[3] : '') : '');
  }
  return '';
};

const formatCurrency = (value: number | null): string => {
  if (!value) return '$0.00';
  return `$${value.toFixed(2)}`;
};

const FiltersPlugin = {
  install: (Vue: VueConstructor): void => {
    Vue.filter('dayjs', formatDate);
    Vue.filter('phone', formatPhoneNumber);
    Vue.filter('currency', formatCurrency);
  }
};

export default FiltersPlugin;
