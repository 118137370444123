import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

// Plugins
import ErrorsPlugin from '@/plugins/errors';
import FiltersPlugin from '@/plugins/filters';
import SnackbarPlugin from '@/plugins/snackbar';
import vuetify from '@/plugins/vuetify';

// dayjs
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
dayjs.extend(dayjsUtc);

// Global Components
import '@/plugins/vee-validate';

// wysiwyg HTML editor
import wysiwyg from 'vue-wysiwyg';

// Styles
import '@fontsource/noto-sans-kr/500.css';
import '@fontsource/cambo/400.css';
import './sass/styles.scss';
import 'vue-wysiwyg/dist/vueWysiwyg.css';

Vue.config.productionTip = false;

Vue.use(ErrorsPlugin);
Vue.use(FiltersPlugin);
Vue.use(SnackbarPlugin);
Vue.use(wysiwyg, {
  hideModules: { table: true, image: true, removeFormat: true, separator: true, code: true }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
