// Utilities
import { ApiService } from '@/services';
import { MemberModule } from '@/store/modules';

class MembersService {
  // Get members Card
  async getCards() {
    const response = await ApiService.api.get('/members/cards');
    return response.data;
  }

  // Set Card as Default
  async setCardAsDefault(cardId: string) {
    const response = await ApiService.api.patch(`members/cards/default/${cardId}`);
    return response.data;
  }

  /**
   * Fetch Cause Information
   *
   * @returns Cause Information
   */
  async fetchCauseInformation(dateRange?: any, filter?: any): Promise<any> {
    if (!filter) {
      filter = {
        pageNumber: 1,
        pageSize: 20
      };
    }
    let dateFilter = '';

    if (dateRange) {
      dateFilter = `?RangeStartDate=${dateRange.startDate}&RangeEndDate=${dateRange.endDate}`;
    }

    const response = await ApiService.api.get(`/members/stats/summary${dateFilter}`, {
      params: filter
    });
    const causeinfo = response.data.summary;

    return causeinfo;
  }

  /**
   * Fetch Cause Information
   *
   * @returns Cause Information
   */
  async fetchDonutChartInformation(): Promise<any> {
    const response = await ApiService.api.get(`/members/stats/summary`);
    const donutData = response.data;

    return donutData;
  }

  getCauseInformation(): any | null {
    return MemberModule.getCauseInformation();
  }
}

const singleton = new MembersService();
export default singleton;
