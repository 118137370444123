
import { Component, Vue } from 'vue-property-decorator';
import { mdiClose } from '@mdi/js';

import UserAvatar from '@/components/UserAvatar.vue';

// Config Variables
import { ActiveTenant } from '../../active-tenant';
import { tenants } from '../../tenant-config';
import { ILink } from '@/types/global.types';
import { FeatureService } from '@/services';

@Component({
  components: {
    UserAvatar
  }
})
export default class OnboardingHeader extends Vue {
  menuDisplay = false;

  ActiveTenant = ActiveTenant;

  currentTenant = tenants[ActiveTenant];
  headerBackground = this.currentTenant.menuHeaderBackground;
  reversedTextColor = this.currentTenant.menuHeaderTextColor;
  upperHeaderImage = this.currentTenant.appHeader.upperHeaderImage;
  lowerHeaderImage = this.currentTenant.appHeader.lowerHeaderImage;
  email = this.currentTenant.email;
  isMultipleCauseEnabled = false;

  icons = {
    closeMenu: mdiClose
  };

  get LeasideLocalLogo(): string {
    return require(`@/assets/logo-leasideLocal.png`);
  }

  get unauthenticatedLinks(): ILink[] {
    const links: ILink[] = [
      { order: 1, path: '/register', text: 'Enroll Now' },
      // using a navigation guard to navigate to a `mailto` link
      { order: 3, path: `mailto: ${this.email}`, text: 'Support' },
      { order: 4, path: '/login', text: 'Sign In' }
    ];

    if (this.isMultipleCauseEnabled) {
      links.push({ order: 2, path: 'https://hopefactory.net', text: 'Browse Charities' });
    }

    links.sort((link1, link2) => link1.order - link2.order);
    return links;
  }

  get links() {
    return this.unauthenticatedLinks;
  }

  get drawerLinks() {
    const links = this.unauthenticatedLinks;
    return links;
  }

  async created(): Promise<void> {
    const response = await FeatureService.getIsMultipleCauseEnabled();
    this.isMultipleCauseEnabled = response.isMultipleCauseEnabled;
  }
}
