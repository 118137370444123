import Vue from 'vue';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import {
  required,
  email,
  numeric,
  min,
  min_value,
  max_value,
  max,
  length,
  digits,
  alpha_num,
  alpha_spaces,
  regex
} from 'vee-validate/dist/rules';

// Register rules
extend('required', required);
extend('email', email);
extend('numeric', numeric);
extend('min', min);
extend('min_value', min_value);
extend('max_value', max_value);
extend('max', max);
extend('digits', digits);
extend('alpha_num', alpha_num);
extend('alpha_spaces', alpha_spaces);
extend('regex', regex);
extend('length', length);

extend('postCode', {
  message: 'Enter a valid Canadian or American postal code',
  validate: (value) => {
    if (
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
        value
      )
    ) {
      return true;
    }
    if (/^\d{5}([- ]{0,1}\d{4})?$/.test(value)) {
      return true;
    }
    return false;
  }
});

extend('phoneCheck', {
  message: 'Enter a valid Canadian phone number',
  validate: (value) => {
    return /^[2-9]\d{2} [2-9]\d{2} \d{4}$/.test(value);
  }
});

/*
/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
/*
getPhoneRegex() {
  if (["United States", "Canada"].includes(this.contact.country)){
    return /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
  }
  return /./;
}
/*
extend("phone", {
  validate: (value, { country }) => {
    if (["United States", "Canada"].includes(country)) {
      return /^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/.test(value);
    }
    return true;
  },
  message: "Phone number is invalid.",
  params: [{ name: "country", isTarget: true }]
});
/*
extend("postal_code", {
  validate: (value, { country }) => {
    if ("United States" == country) {
      return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
    } else if ("Canada" == country) {
      return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value);
    }
    return true;
  },
  message: "Phone number is invalid.",
  params: [{ name: "country", isTarget: true }]
});*/
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
